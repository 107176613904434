import React, {useState, useEffect,} from "react";
import "./SummaryLabeledView.css";
import Text from "../../Common/Text";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
  Autocomplete,
  useLoadScript,
} from "@react-google-maps/api";
import { setKey, geocode, RequestType } from "react-geocode";
import { ReactComponent as EditFilled } from "../../../assets/svgs/EditFilled.svg";
import { ReactComponent as BinFilled } from "../../../assets/svgs/BinFilled.svg";
import { ReactComponent as VerticalLine } from "../../../assets/svgs/VerticalLine.svg";
import ButtonPrimary from "../../../Component/Common/Buttons/ButtonPrimary/ButtonPrimary";
import TooltipPopup from "../../../Component/Common/TooltipPopup/TooltipPopup";
import { GOOGLE_API_KEY } from "../../../constants/env";

export default function SummaryLabeledView({
  heading,
  data,
  onEditClicked,
  deleteOption,
  onRemoveClicked,
  sideLabel,
  flag,
  isExpiredEvent, 
  tooltipInfo,
  imageURL,
  fees,
  currencySymbol,
  ticketPrice,
  stageReleases,
}) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: ["places"],
  });

  const [formattedAddress, setFormattedAddress] = useState("");


  useEffect(() => {
    setKey(GOOGLE_API_KEY);
   data && geocode(
      RequestType.LATLNG,
      `${data[0]?.result}` + "," + `${data[1]?.result}`
    )
      .then(({ results }) => {
        const address = results[0].formatted_address;
        setFormattedAddress(address);
      })
      .catch(console.error);
  }, [data]);

  return (
    <div className="summary-labeled-div CommonBoxCont">
      <div
        className="summary-header-inner-container"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 40,
        }}
      >
        <div className="generic-common-row-div">
          {/* <Text marginRight={16} variant={"m500"}> */}
          <Text variant={"m500"}>{heading}</Text>
          {tooltipInfo && <TooltipPopup />}
          {sideLabel && <VerticalLine />}
          {sideLabel && (
            <Text
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue("--grey")}
              marginLeft={16}
              variant={"r400"}
            >
              {sideLabel}
            </Text>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {/* {!isExpiredEvent && ( */}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
            onClick={() => onEditClicked()}
          >
            <Text
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue("--midBlue")}
              marginRight={8}
              variant={"m300"}
            >
              Edit
            </Text>
            <EditFilled height={24} width={24} />
          </div>

          {/* {deleteOption && !isExpiredEvent && ( */}
          {deleteOption && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
              onClick={() => onRemoveClicked()}
            >
              <Text
                color={getComputedStyle(
                  document.documentElement
                ).getPropertyValue("--red")}
                marginRight={8}
                marginLeft={24}
                variant={"m300"}
              >
                Remove
              </Text>
              <BinFilled
                fill={getComputedStyle(
                  document.documentElement
                ).getPropertyValue("--red")}
                height={24}
                width={24}
              />
            </div>
          )}
        </div>
      </div>
      {flag === "ticket" ? (
        <div className="SummaryBodyCont" style={{ width: "100%" }}>
          {data.map((item, ind) => {
            return (
              <div
                className="SummaryGroup"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: ind === data.length - 1 ? 0 : 24,
                }}
              >
                <div className="SummaryLabel">
                  <Text
                    marginRight={30}
                    width={246}
                    // textWrap={"nowrap"}
                    variant={"m300"}
                  >
                    {item.label}
                  </Text>
                </div>
                <div className="generic-common-row-div SummaryDetails">
                  <Text
                    // width={1000}
                    color={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue("--midBlue")}
                    variant={"m300"}
                  >
                    {item.result}
                  </Text>
                </div>
              </div>
            );
          })}

        {stageReleases && stageReleases.length > 0 ? 
        <div className="ticket-summary-payment-overview-container">
        <Text variant={"m500"}>{"Payment overview"}</Text>
        <Text marginTop={16} variant={"r300"}>
          {
            "Calculations are based on your chosen fee structure at the previous step."
          }
        </Text>
          {stageReleases.map((item, index) => {
            return (
              item?.stageOfferName?.trim() !== "" &&
              parseInt(item?.stagePrice) > 0 && (
                <div style={{ marginTop: 32, width: "100%" }}>
                  <Text variant="m400">{`Staged release (${item?.stageOfferName})`}</Text>
                  <div
                    style={{ width: "100%" }}
                    className="generic-common-row-div FullPriceBodyCont"
                  >
                    <div
                      className="generic-common-row-div-space-between FullPriceGroup"
                      style={{
                        marginTop: 16,
                        paddingRight: 32,
                        width: "50%",
                      }}
                    >
                      <span className="FullPriceLabel">
                        <Text variant="r300" marginBottom={8}>
                          Total price customer pays
                        </Text>
                      </span>
                      <span className="FullPriceDetails">
                        <Text variant="b300" marginBottom={8}>
                          {`${currencySymbol}${parseFloat(
                            !fees?.isAddFeesToTicketPrice
                              ? parseInt(item?.stagePrice) +
                                fees?.platformFee +
                                (item?.stagePrice * fees?.platformFeePct) /
                                  100 +
                                fees?.ticketGuardFee +
                                (fees?.isAddBookingFees
                                  ? fees?.bookingPercentValue &&
                                    fees?.maxBookingFee
                                    ? fees?.maxBookingFee <
                                      (item?.stagePrice *
                                        fees?.bookingPercentValue) /
                                        100
                                      ? fees?.maxBookingFee
                                      : (item?.stagePrice *
                                          fees?.bookingPercentValue) /
                                        100
                                    : 0
                                  : 0)
                              : parseInt(item?.stagePrice) +
                                (fees?.isAddBookingFees
                                  ? fees?.maxBookingFee <
                                    (item?.stagePrice *
                                      fees?.bookingPercentValue) /
                                      100
                                    ? fees?.maxBookingFee
                                    : (item?.stagePrice *
                                        fees?.bookingPercentValue) /
                                      100
                                  : 0)).toFixed(2)
                          }`}
                        </Text>
                      </span>
                    </div>
                    <div
                      className="generic-common-row-div-space-between FullPriceGroup"
                      style={{
                        marginTop: 16,
                        paddingLeft: 32,
                        width: "50%",
                      }}
                    >
                      <span className="FullPriceLabel">
                        <Text variant="r300" marginBottom={8}>
                          Amount received by organiser
                        </Text>
                      </span>
                      <span className="FullPriceDetails">
                        <Text variant="b300" marginBottom={8}>
                          {`${currencySymbol}${parseFloat(
                            (!fees?.isAddFeesToTicketPrice
                              ? parseInt(item?.stagePrice) +
                                fees?.platformFee +
                                (item?.stagePrice * fees?.platformFeePct) /
                                  100 +
                                fees?.ticketGuardFee +
                                (fees?.isAddBookingFees
                                  ? fees?.maxBookingFee <
                                    (item?.stagePrice *
                                      fees?.bookingPercentValue) /
                                      100
                                    ? fees?.maxBookingFee
                                    : (item?.stagePrice *
                                        fees?.bookingPercentValue) /
                                      100
                                  : 0)
                              : parseInt(item?.stagePrice) +
                                (fees?.isAddBookingFees
                                  ? fees?.maxBookingFee <
                                    (item?.stagePrice *
                                      fees?.bookingPercentValue) /
                                      100
                                    ? fees?.maxBookingFee
                                    : (item?.stagePrice *
                                        fees?.bookingPercentValue) /
                                      100
                                  : 0)) -
                            (fees?.platformFee +
                              (item?.stagePrice * fees?.platformFeePct) /
                                100 +
                              fees?.ticketGuardFee +
                              (fees?.isAddBookingFees
                                ? fees?.maxBookingFee <
                                  (item?.stagePrice *
                                    fees?.bookingPercentValue) /
                                    100
                                  ? fees?.maxBookingFee * 0.2
                                  : ((item?.stagePrice *
                                      fees?.bookingPercentValue) /
                                      100) *
                                    0.2
                                : 0))).toFixed(2)
                          }`}
                        </Text>
                      </span>
                    </div>
                  </div>
                </div>
              )
            );
          }
        )}</div> :
          
          (parseInt(ticketPrice) > 0 && ticketPrice !== '0.00' && <div className="ticket-summary-payment-overview-container">
            <Text variant={"m500"}>{"Payment overview"}</Text>
            <Text marginTop={16} variant={"r300"}>
              {
                "Calculations are based on your chosen fee structure at the previous step."
              }
            </Text>

            <Text variant="m400" marginTop={32}>
              Full price ticket
            </Text>

            <div
              style={{ width: "100%" }}
              className="generic-common-row-div FullPriceBodyCont"
            >
              <div
                className="generic-common-row-div-space-between FullPriceGroup"
                style={{
                  marginTop: 16,
                  paddingRight: 32,
                  width: "50%",
                }}
              >
                <span className="FullPriceLabel">
                  <Text variant="r300" marginBottom={8}>
                    Total price customer pays
                  </Text>
                </span>
                <span className="FullPriceDetails">
                  <Text variant="b300" marginBottom={8}>
                    {`${currencySymbol}${parseFloat(
                      !fees?.isAddFeesToTicketPrice
                        ? parseInt(ticketPrice) +
                          fees?.platformFee +
                          (ticketPrice * fees?.platformFeePct) / 100 +
                          fees?.ticketGuardFee +
                          (fees?.isAddBookingFees
                            ? fees?.bookingPercentValue && fees?.maxBookingFee
                              ? fees?.maxBookingFee <
                                (ticketPrice * fees?.bookingPercentValue) / 100
                                ? fees?.maxBookingFee
                                : (ticketPrice * fees?.bookingPercentValue) /
                                  100
                              : 0
                            : 0)
                        : parseInt(ticketPrice) +
                          (fees?.isAddBookingFees
                            ? fees?.maxBookingFee <
                              (ticketPrice * fees?.bookingPercentValue) / 100
                              ? fees?.maxBookingFee
                              : (ticketPrice * fees?.bookingPercentValue) / 100
                            : 0)).toFixed(2)
                    }`}
                  </Text>
                </span>
              </div>
              <div
                className="generic-common-row-div-space-between FullPriceGroup"
                style={{
                  marginTop: 16,
                  paddingLeft: 32,
                  width: "50%",
                }}
              >
                <span className="FullPriceLabel">
                  <Text variant="r300" marginBottom={8}>
                    Amount received by organiser
                  </Text>
                </span>
                <span className="FullPriceDetails">
                  <Text variant="b300" marginBottom={8}>
                    {`${currencySymbol}${parseFloat(
                      (!fees?.isAddFeesToTicketPrice
                        ? parseInt(ticketPrice) +
                          fees?.platformFee +
                          (ticketPrice * fees?.platformFeePct) / 100 +
                          fees?.ticketGuardFee +
                          (fees?.isAddBookingFees
                            ? fees?.maxBookingFee <
                              (ticketPrice * fees?.bookingPercentValue) / 100
                              ? fees?.maxBookingFee
                              : (ticketPrice * fees?.bookingPercentValue) / 100
                            : 0)
                        : parseInt(ticketPrice) +
                          (fees?.isAddBookingFees
                            ? fees?.maxBookingFee <
                              (ticketPrice * fees?.bookingPercentValue) / 100
                              ? fees?.maxBookingFee
                              : (ticketPrice * fees?.bookingPercentValue) / 100
                            : 0)) -
                      (fees?.platformFee +
                        (ticketPrice * fees?.platformFeePct) / 100 +
                        fees?.ticketGuardFee +
                        (fees?.isAddBookingFees
                          ? fees?.maxBookingFee <
                            (ticketPrice * fees?.bookingPercentValue) / 100
                            ? fees?.maxBookingFee * 0.2
                            : ((ticketPrice * fees?.bookingPercentValue) /
                                100) *
                              0.2
                          : 0))).toFixed(2)
                    }`}
                  </Text>
                </span>
              </div>
            </div>
          </div> )}
        </div>
      ) : flag === "map" ? (
        <div className="SummaryBodyCont kkk" style={{ width: "100%" }}>

<div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 24,
                  width: "100%",
                }}
              >
                <Text
                  marginRight={30}
                  width={226}
                  textWrap={"nowrap"}
                  variant={"m300"}
                >
                  {data[2].label}
                </Text>
                <Text
                  color={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue("--midBlue")}
                  variant={"m300"}
                >
                  {formattedAddress || ''}
                </Text>
              </div>

          <div
            className="LatLongMapCont"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div
              className="LatLongCont"
              style={{
                display: "flex",
                flexDirection: "column",
                width: "50%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text
                  marginRight={30}
                  width={226}
                  textWrap={"nowrap"}
                  variant={"m300"}
                >
                  {data[0].label}
                </Text>
                <Text 
                  color={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue("--midBlue")}
                  variant={"m300"}
                >
                  {data[0].result}
                </Text>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 24,
                }}
              >
                <Text
                  marginRight={30}
                  width={226}
                  textWrap={"nowrap"}
                  variant={"m300"}
                >
                  {data[1].label}
                </Text>
                <Text
                  color={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue("--midBlue")}
                  variant={"m300"}
                >
                  {data[1].result}
                </Text>
              </div>
            </div>
            {isLoaded && (
              <div
                className="MapCont"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "50%",
                  height: 282,
                }}
              >
                <GoogleMap
                  mapContainerStyle={{ flex: 1, borderRadius: 4 }}
                  mapContainerClassName="map-container"
                  center={data[2]?.result || { lat: 0, lng: 0 }}
                  zoom={15}
                  defaultCenter={data[2]?.result}
                >
                  <Marker position={data[2]?.result}></Marker>
                </GoogleMap>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="SummaryBodyCont free-perTicket-addSolid-block" style={{ width: "70%" }}>
          {data.map((item, ind) => {
            return (
              <div
                className="SummaryGroup"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: ind === data.length - 1 ? 0 : 24,
                }}
              >
                <div
                  className="SummaryLabel"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    width: 226,
                  }}
                >
                  <Text
                    //marginRight={30}
                    width={226}
                    // textWrap={"nowrap"}
                    variant={"m300"}
                  >
                    {item.label}
                  </Text>
                </div>

                <div
                  className="SummaryDetails"
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  {((heading === "About" && ind === 6) || (heading === data[0].result && ind === 6)) ? (
                    <div
                      className="event-details-inner-imgContainer"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                      }}
                    >
                      <img
                        content="center"
                        alt=""
                        style={{
                          aspectRatio: 16 / 9,
                          objectFit: "cover",
                          display: "block",
                          border: "none",
                          borderRadius: 4,
                          // maxHeight: 413,
                        }}
                        src={imageURL ? imageURL : data[ind].result}
                      />
                    </div>
                  ) : (
                    <Text hasHtml= {ind === 1 ? true : false}
                      style={{ width: heading === "Fees per ticket sold" ? 'auto' : "100%" }}
                      color={"var(--midBlue)"}
                      variant={"m300"}
                    >
                      {item.result}
                    </Text>
                  )}
                  {item.result1 && (
                    <Text marginLeft={0} color={"var(--grey"} variant={"m300"}>
                      {item.result1}
                    </Text>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
