import React, {useEffect, useState} from "react";
import "./InfoTags.css";
import Text from "../Text";

export default function InfoTags({
  icon,
  text,
  onChangeVisibility,
  isDraft,
}) {

  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
      onChangeVisibility(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  if (!visible) {
    return null;
  }

  return (
    <div style={{top: isDraft ? '174px' : '130px', right: isDraft ? '226px' : '132px'}} className="tag-container">
    <div className="icon-container">
      {icon}
    </div>
    <Text color={"var(--greenSuccess)"} variant="m300">{text}</Text>
  </div>
  );
}
