import { combineReducers } from "redux";
import authToken from "./authToken";
import userData from "./userData";
import eventData from "./eventData";
import draftData from "./draftData";
import currencyData from "./currencyData";
import countryData from "./countryData";
import eventCategoryData from "./eventCategoryData";
import bannerContent from "./bannerContent";
import showLoader from "./showLoader";
import showLoaderMailChimp from "./showLoaderMailChimp";
import settingsData from "./settingsData";
import languageLocale from "./languageLocale";
import selectedEventData from "./selectedEventData";
import walletIndex from "./walletIndex";
import connectWalletForNFT from "./connectWalletForNFT";
import showDraftEvent from "./showDraftEvent";
import eventType from "./eventType";
import selectedEventCategoryData from "./selectedEventCategoryData";
import eventsPaymentMethodRequired from "./eventsPaymentMethodRequired";

const rootReducer = combineReducers({
  authToken,
  userData,
  eventData,
  draftData,
  currencyData,
  countryData,
  eventCategoryData,
  bannerContent,
  showLoader,
  showLoaderMailChimp,
  settingsData,
  languageLocale,
  selectedEventData,
  walletIndex,
  connectWalletForNFT,
  showDraftEvent,
  eventType,
  selectedEventCategoryData,
  eventsPaymentMethodRequired,
});

export default rootReducer;
