import { SHOW_ACTIVITY_INDICATOR_MAILCHIMP } from '../actions';

export default function showLoaderMailChimp(state = null, action = {}) {
  switch (action.type) {
    case SHOW_ACTIVITY_INDICATOR_MAILCHIMP: {
      return {
        ...state,
        showLoaderMailChimp: action.payload,
      };
    }

    default:
      return state;
  }
}