import { EVENTS_PAYMENT_METHOD_REQUIRED } from "../actions";

export default function eventsPaymentMethodRequired(state = false, action = {}) {
  switch (action.type) {
    case EVENTS_PAYMENT_METHOD_REQUIRED: {
      return {
        ...state,
        eventsPaymentMethodRequired: action.payload,
      };
    }

    default:
      return state;
  }
}