export function validateImageUrl(url) {
    return new Promise((resolve) => {
        if (!url) {
            resolve(false);
            return;
        }

        const img = new Image();
        img.src = url;
        
        img.onload = () => resolve(true);   // Image exists
        img.onerror = () => resolve(false); // Image not found (404)
    });
}
