import React, { useLayoutEffect } from "react";
import "./terms.css";
import Footer from "../../../src/Component/FooterNew/FooterNew";
import { useLocation } from "react-router-dom";

function Terms() {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <div className="terms-service-main">
      <div className="terms-service-block">
        
        <div className="terms-service-inner">
          <div className="terms-service-inner-lft">

          <h2 style={{ color: "var(--midBlue)", marginTop: '30px' }}>Twotixx Box Office </h2>
        <h2 style={{ color: "var(--midBlue)", marginTop: "-10px", marginBottom: "48px" }}>
          Terms of Service
        </h2>

            <div className="terms-service-item">
              <h3>1. Introduction</h3>
              <div className="terms-service-item-inner">
                <div className="terms-service-item-rht">
                  <p>
                    This page, together with our Privacy Policy and the Website
                    Terms of Use tells you information about us and the legal
                    terms and conditions (the "Terms") on which we make
                    available to you our online solution for selling event
                    tickets and associated products and services (the
                    "Service").
                  </p>
                  <p className="not-first-child">
                    Please read these Terms carefully and make sure that you
                    understand them before signing up to the Service.
                  </p>
                </div>
              </div>
            </div>

            <div className="terms-service-item">
              <h3>2. Information about us</h3>
              <div className="terms-service-item-inner">
                <div className="terms-service-item-rht">
                  <p>
                    TTwotixx.com and any of their sub-domains is a site operated
                    by Twotixx, which is the trading name of Twotixx Ltd ("we",
                    “our”, “us”). We are registered in England and Wales under
                    company number 15184405 and have our registered office at 7
                    Bell Yard, London.
                  </p>
                  <p className="not-first-child">
                    You can contact us by emailing
                    <a
                      href="mailto:hello@twotixx.com"
                      style={{
                        color: "var(--midBlue)",
                        textDecoration: "underline",
                        marginLeft: "5px",
                      }}
                    >
                      hello@twotixx.com
                    </a>
                    .
                  </p>
                </div>
              </div>

              {/* <div className="terms-service-item-inner">
                        <div className="terms-service-item-lft"><h4>2.2</h4></div>
                        <div className="terms-service-item-rht">
                          <p>All tickets are sold by the respective event organisers, and this platform acts as a facilitator for the transaction.</p>
                        </div>
                    </div> */}
            </div>

            <div className="terms-service-item">
              <h3>3. Our contract with you</h3>
              <div className="terms-service-item-inner">
                <div className="terms-service-item-rht">
                  <p>
                    You agree to these Terms and you enter into a binding
                    contract with us when you sign up for a Twotixx account by
                    clicking “Sign Up” or similar buttons on our website or
                    download any of our apps. If you do not agree to any portion
                    of these Terms of Service, please do not use or access the
                    Service.
                  </p>
                  <p className="not-first-child">
                    We may amend these Terms from time to time. You should check
                    the website (www.twotixx.com/terms-and-conditions) from time
                    to time to review the then current Terms, as they are
                    binding on you. Certain provisions of these Terms may be
                    superseded by expressly designated legal notices or terms:
                    (a) located on particular pages at the website; or (b)
                    notified to you, from time to time.
                  </p>
                </div>
              </div>
            </div>

            <div className="terms-service-item">
              <h3>4. Nature of the service</h3>
              <div className="terms-service-item-inner">
                <div className="terms-service-item-rht">
                  <p>
                    Our Service is web-based and allows registration for an
                    account to access our online platform to sell and manage
                    event tickets and associated physical or digital products
                    and services. You may register for a Twotixx Box Office
                    account only if you are the event organiser or an authorised
                    ticket seller (and have the event organisers expressed
                    written permission for all the events you are selling
                    tickets for) (“Event Organiser”). If you will be using the
                    Services on behalf of an entity, you agree to these Terms on
                    behalf of that entity and represent that you have the
                    authority to do so.
                  </p>
                  <p className="not-first-child">
                    We provide the Service to you and have no relationship with
                    your customers (“Consumers"). When you sell tickets or other
                    products and services to Consumers through the Service, the
                    contractual relationship is between you and the Consumer.
                    You are responsible for providing your own terms of sale
                    with your Consumers and accordingly, we will direct any
                    Consumer who raises queries regarding your events or your
                    other products and services to you. You agree:
                  </p>

                  <ul>
                    <li>
                      to handle all communication with your Consumers and not to
                      refer them to us for customer support;
                    </li>
                    <li>
                      to respond to any queries you receive from Consumers
                      within 5 days;
                    </li>
                    <li>
                      to accept all liability in relation to the contractual
                      relationship between you and the Consumer;
                    </li>
                    <li>
                      to notify your Consumers immediately and update your
                      Twotixx event page if your event is cancelled, postponed,
                      or has a material change from the original information
                      given when the event was first put on sale.
                    </li>
                  </ul>

                  <p className="not-first-child">
                    We reserve the right to suspend or terminate your access to
                    the Service if you fail to adhere to these contractual
                    relationship requirements with your Consumers.
                  </p>

                  <p className="not-first-child">
                    When using the Service, you shall:{" "}
                  </p>
                  <ul>
                    <li>
                      comply with applicable laws and obligations related to
                      your provision of events, products or services to
                      Consumers, or receipt of booking fees, transaction fees,
                      or charitable donations;
                    </li>
                    <li>
                      provide us with such information and material as we may
                      reasonably require in order to supply the Services and
                      ensure that such information is accurate in all material
                      respects;
                    </li>
                    <li>
                      maintain and promptly update all information and material
                      as may be required by us in order to supply the Services
                      to keep it true, accurate and complete; and
                    </li>
                    <li>
                      cooperate with us in all matters relating to the Service.
                    </li>
                  </ul>

                  <p className="not-first-child">
                    Access to the Service may be suspended temporarily and
                    without notice in the case of system failure, maintenance or
                    repair or for reasons beyond our control. Your access to the
                    Service may also be suspended, or your account may be
                    cancelled at any point, at our discretion, including (but
                    not limited to) if we suspect that you are using the Service
                    for fraudulent or criminal purposes, if we are advised to by
                    law enforcement agencies or if you have failed to pay the
                    service fee (in which case we would typically notify you of
                    the non-payment before suspension or cancellation). We will
                    not be liable for any loss or damage that may arise from
                    misuse of our service.{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="terms-service-item">
              <h3>5. Use of Email Communication Tool</h3>
              <div className="terms-service-item-inner">
                <div className="terms-service-item-rht">
                  <p>
                    As an event organiser using our platform, you are granted
                    access to our email tool to facilitate direct communication
                    with your event attendees. It is imperative that this tool
                    be used responsibly and professionally. You are expected to
                    maintain a high standard of communication, free from
                    offensive language, threats, harassment, or any form of
                    scamming activity. Any breach of these standards, including
                    the use of our platform to engage in unethical or illegal
                    conduct, will result in immediate investigation, and may
                    lead to the suspension or termination of your account. We
                    are committed to promoting a safe and respectful
                    environment; therefore, we reserve the right to take
                    appropriate action to protect our users and maintain the
                    integrity of our service.
                  </p>
                </div>
              </div>
            </div>

            <div className="terms-service-item">
              <h3>6. Your account</h3>
              <div className="terms-service-item-inner">
                <div className="terms-service-item-rht">
                  <p>
                    On sign up you are immediately able to set up your event,
                    create tickets for your event and, subject to having set up
                    an account with a Payment Processor, sell tickets for your
                    event. Before you begin selling you must also verify your
                    identity.
                  </p>
                  <p className="not-first-child">
                    You must be 18 to set up an account.
                  </p>
                  <p className="not-first-child">
                    You can make changes to your account in accordance with the
                    functionality available in your account dashboard. You may
                    close your account at any point in time: 72 hours notice is
                    required to close your account. You should make sure that
                    you have taken a copy of any of Your Content that you wish
                    to retain before closure of your account is effective by
                    using the export functionality in your account dashboard.
                    Your Content will be deleted after closure of your account.
                  </p>
                  <p className="not-first-child">
                    For your convenience we will keep your account open for 24
                    months after your last sign in, in case you decide to use
                    our Service again. Prior to closing your account and
                    deleting Your Content in your account, we will attempt to
                    send warning emails to your account email address so that
                    you have an opportunity to keep your account open or export
                    any data that you wish to retain.
                  </p>
                  <p className="not-first-child">
                    You can close your account at any time by emailing our
                    customer support team at
                    <a
                      href="mailto:hello@twotixx.com"
                      style={{
                        color: "var(--midBlue)",
                        textDecoration: "underline",
                        marginLeft: "5px",
                        marginRight: "5px",
                      }}
                    >
                      hello@twotixx.com
                    </a>
                    from the email address of the account owner.
                  </p>
                  <p className="not-first-child">
                    We will not refund any fees already paid for the Service or
                    for any Credits you may have at the time of closure.
                  </p>
                  <p className="not-first-child">
                    <span>Account Security</span> – You are responsible for
                    maintaining the confidentiality of your account, and are
                    fully responsible for all activities that occur as a result
                    of access to your account using your access credentials.
                    Where we have given you (or where you have chosen) a
                    password or API key which enables you to access your
                    account, certain parts of our website or apps, or you have
                    invited team members to access your account, parts of our
                    site or apps, you are responsible for keeping this password
                    or API key confidential. You should not share a password or
                    API key with anyone. You agree to immediately notify us by
                    emailing help@twotixx.com of any unauthorised use of your
                    password, API key or account or any other breach of
                    security.
                  </p>

                  <p className="not-first-child">
                    We will not be liable for any loss or damage arising from
                    your failure to comply with this provision.{" "}
                  </p>
                  <p className="not-first-child">
                    Transmission of information via the internet is not
                    completely secure. We cannot guarantee the security of your
                    data transmitted to our site and any transmission is at your
                    own risk. Once we have received your information, we will
                    use strict procedures and security features to try to
                    prevent unauthorised access.{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="terms-service-item">
              <h3>7. Payment Processors</h3>
              <div className="terms-service-item-inner">
                <div className="terms-service-item-rht">
                  <p>
                    Our Service allows you to take payments for your events and
                    products and services from Consumers online using payment
                    processors with whom we have partnered (“Payment
                    Processors”): Stripe Payments Europe Ltd and PayPal Pte.
                    Ltd. Your use of the payment service requires you to set up
                    an account with the Payment Processor you wish to use which
                    will be subject to the Payment Processor’s own terms and
                    conditions and privacy policy. You will have a direct
                    relationship with the Payment Processor and will be required
                    to read and accept their terms and conditions before taking
                    payments from Consumers via the payment services offered
                    through our Service.
                  </p>
                  <p className="not-first-child">
                    In carrying out certain functions, the Payment Processor
                    acts as a data processor to both you and us but in other
                    respects it acts as controller. Payment Processor activities
                    carried out as controller are carried out in accordance with
                    the Payment Processor’s own privacy policy. By using our
                    payment service, you acknowledge and agree to us sharing
                    personal information and/or information about your
                    transactions made via our Service with the Payment
                    Processor.
                  </p>

                  <p className="not-first-child">
                    If we receive notice from the Payment Processor that your
                    use of our payment service is in breach of their terms or of
                    any agreement between you and them, you and us or you and a
                    Consumer or appears fraudulent, in breach of money
                    laundering regulations or is part of other harmful activity,
                    we may take actions including, but not limited to, those
                    necessary to rectify your breach, removing your ability to
                    use our payment service and/or the suspension or termination
                    of your account. The Payment Processor reserves the right to
                    refuse the use of its payment services to anyone, for any
                    reason, and at any time.
                  </p>

                  <p className="not-first-child">
                    See the Stripe terms and privacy policy at
                    <a
                      style={{
                        color: "var(--midBlue)",
                        textDecoration: "underline",
                        marginLeft: "5px",
                        marginRight: "5px",
                      }}
                    >
                      Stripe Services Agreement
                    </a>
                    and
                    <a
                      style={{
                        color: "var(--midBlue)",
                        textDecoration: "underline",
                        marginLeft: "5px",
                        marginRight: "5px",
                      }}
                    >
                      Stripe Global Privacy Policy.
                    </a>
                  </p>

                  <p className="not-first-child">
                    See the Paypal terms and privacy policy at
                    <a
                      style={{
                        color: "var(--midBlue)",
                        textDecoration: "underline",
                        marginLeft: "5px",
                        marginRight: "5px",
                      }}
                    >
                      Legal Agreements for PayPal Services.
                    </a>
                  </p>

                  <p className="not-first-child">
                    We have no responsibility for, and hereby disclaim all
                    liability (including, but not limited to) any losses, costs,
                    fees and penalties of whatever nature arising from the
                    service provided to you by the Payment Processor, including
                    the acts and omissions of the Payment Processor.
                  </p>
                </div>
              </div>
            </div>

            <div className="terms-service-item">
              <h3>8. Our fees</h3>
              <div className="terms-service-item-inner">
                <div className="terms-service-item-rht">
                  <p>
                    The fees for using our Service are set out on our website
                    and are charged on a usage basis. A chargeable use or
                    activity is the sale of a ticket, the reservation of a seat,
                    the sale of an associated product or service or other
                    chargeable activity that we make available through the
                    Service (an “Activity”). We may also offer add-on services
                    which will be charged as specified on our website or as
                    agreed with you.
                  </p>
                  <p className="not-first-child">
                    Event Organiser fees are charged periodically to the billing
                    card kept on file.
                  </p>
                  <p className="not-first-child">
                    Pay as you go fees will be charged to you either:
                  </p>
                  <ul>
                    <li>
                      iPeriodically, and no less frequently than weekly, by
                      charging your account with a Payment Processor or the
                      credit or debit card you have provided.
                    </li>
                  </ul>
                  <p className="not-first-child">
                    We will send you a monthly statement and invoice by email
                    showing all fees and applicable taxes you have paid or are
                    payable to us.
                  </p>
                  <p className="not-first-child">
                    Our fees are non-refundable, even if an event is cancelled.{" "}
                  </p>
                  <p className="not-first-child">
                    In some circumstances we may agree to a different process
                    for the payment of our fees, in which case, each invoice
                    submitted to you by us is payable within 10 days from the
                    date of the invoice
                  </p>
                  <p className="not-first-child">
                    Without prejudice to any other right or remedy that we may
                    have, if you fail to pay us on or before the due date
                    (including if a card payment fails), we may:
                  </p>
                  <ul>
                    <li>
                      charge interest at a rate of 7% from the due date for
                      payment until payment is made and you shall pay interest
                      immediately on demand; and/or
                    </li>
                    <li>
                      suspend the provision of the Service until payment has
                      been made in full; and/or{" "}
                    </li>
                    <li>
                      suspend the issue of any Credits until payment has been
                      made in full.
                    </li>
                  </ul>
                  <p className="not-first-child">
                    Box offices may issue free tickets or free associated
                    products and services on our platform and incur no fees.
                    Please note that we operate a ‘fair use’ policy on this
                    offer. This largely relates to box offices who deliberately
                    avoid fees for what is actually a paid event, or box offices
                    that have high volume events.
                  </p>
                  <p className="not-first-child">For example:</p>
                  <ul>
                    <li>
                      Box offices that use Twotixx for free but collect ticket
                      funds or payment for associated products and services by
                      circumventing our payment providers and therefore our fees
                      in order to benefit from this offer. E.g. issuing a free
                      ticket on Twotixx but asking customers to pay on the door
                    </li>
                    <li>
                      Box offices that create a free event issuing high volumes
                      of tickets which creates excessive costs for us to
                      deliver. This is not the intended use of our free offer.
                      If you expect to issue over 2,000 free tickets please
                      reach out to hello@twotixx.com. Please note we reserve the
                      right to monitor ticket volume on accounts and we will
                      contact you if we find your account to be in excess of the
                      fair use volume
                    </li>
                  </ul>
                  <p className="not-first-child">
                    These examples are not exhaustive and we reserve the right
                    to withdraw our offer of free ticketing if we have reasons
                    to consider the use of our platform to not meet our ‘fair
                    use’ criteria. Please contact
                    <a href="mailto:hello@twotixx.com"
                      style={{
                        color: "var(--midBlue)",
                        textDecoration: "underline",
                        marginLeft: "5px",
                        marginRight: "5px",
                      }}
                    >
                      hello@twotixx.com
                    </a>
                    if you have any queries.
                  </p>
                  <p className="not-first-child">
                    Box offices identified as operating outside of our ‘fair
                    use’ policy will be asked to start taking payment via our
                    platform. Box offices who do not comply with this may be
                    suspended.
                  </p>
                </div>
              </div>
            </div>

            <div className="terms-service-item">
              <h3>9. Use of our website</h3>
              <div className="terms-service-item-inner">
                <div className="terms-service-item-rht">
                  <p>
                    Your use of our website is governed by our Website Terms of
                    Use. Please take the time to read these, as they contain
                    important terms which apply to you.
                  </p>
                </div>
              </div>
            </div>

            <div className="terms-service-item">
              <h3>10. How we use your personal information</h3>
              <div className="terms-service-item-inner">
                <div className="terms-service-item-rht">
                  <p>
                    We only use your personal information in accordance with our
                    Privacy Policy. Please take the time to read our Privacy
                    Policy, as it includes some important terms which apply to
                    you.{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="terms-service-item">
              <h3>
                11. Use of Consumers’ and event attendees’ personal information
              </h3>
              <div className="terms-service-item-inner">
                <div className="terms-service-item-rht">
                  <p>
                    Protection of personal information is very important to us.
                  </p>
                  <p className="not-first-child">
                    For any personal information provided by your Consumers that
                    we handle as part of the Service ("Customer Data"), the
                    provisions of this section 10 apply.
                  </p>
                  <p className="not-first-child">
                    Data Protection Law means: (i) any data protection
                    legislation from time to time in force in the UK and the EU
                    including the Data Protection Act 2018 and the UK GDPR, the
                    General Data Protection Regulation ((EU) 2016/679) and any
                    other applicable law or regulation relating to privacy; and
                    (ii) all data protection and privacy legislation applicable
                    to a party in the processing of personal data pursuant to
                    the provision of the Service. You and we agree to comply
                    with all applicable Data Protection Laws and that you are
                    the controller and we are a processor of personal data in
                    Customer Data.
                  </p>
                  <p className="not-first-child">You hereby agree that:</p>
                  <ul>
                    <li>
                      you will ensure that you have all necessary appropriate
                      consents and notices in place to enable lawful transfer of
                      Customer Data to us for the duration of Service and for us
                      to carry out the processing in accordance with the
                      provision of the Service, including informing Consumers
                      and event attendees of how their Customer Data will be
                      handled by you and by us in connection with your use of
                      the Service; and;
                    </li>
                    <li>
                      you have sole responsibility for establishing and
                      maintaining the lawful basis of the our processing of
                      Customer Data under these Terms, including where
                      applicable the obtaining of all necessary consents from
                      the customers and event attendees; and
                    </li>
                    <li>
                      where you export your Customer Data during your use of the
                      Service (for example exporting a list as a spreadsheet,
                      downloading data to the check-in app, or synchronising a
                      MailChimp account), you are responsible for ensuring that
                      your use of that Customer Data is compliant with Data
                      Protection Laws and the terms in place between you and
                      Consumers and/or event attendees.
                    </li>
                  </ul>
                  <p className="not-first-child">
                    A description of our processing of Customer Data is set out
                    in Appendix 1 to these Terms.
                  </p>
                  <p className="not-first-child">
                    In respect of our handling of the Customer Data, we agree
                    that we shall:{" "}
                  </p>
                  <ul>
                    <li>
                      only process Customer Data to provide the Service to you
                      in accordance with the functionality of the Service, these
                      Terms and lawful instructions reasonably given by you to
                      us from time to time, or as otherwise required by law and
                      will not further sell or use Customer Data in any manner;
                    </li>
                    <li>
                      notify you if, in our opinion, an instruction from you
                      infringes any Data Protection Law (provided always that
                      you acknowledge that you remain solely responsible for
                      obtaining independent legal advice regarding the legality
                      of your instructions and that a failure or delay by us to
                      identify that an instruction infringes Data Protection Law
                      shall not cause us to be in breach of our terms nor
                      relieve you from your liability under this these Terms);
                    </li>
                    <li>
                      ensure that our personnel engaged in the handling of
                      Customer Data are informed of the confidential nature of
                      the Customer Data and are subject to binding
                      confidentiality obligations;
                    </li>
                    <li>
                      ensure that we have in place appropriate technical and
                      organisational measures to protect against unauthorised or
                      unlawful processing of Customer Data and against
                      accidental loss or destruction of, or damage to, Customer
                      Data, appropriate to the harm that might result from the
                      unauthorised or unlawful processing or accidental loss,
                      destruction or damage and the nature of the data to be
                      protected, having regard to the state of technological
                      development and the cost of implementing any measures
                      (those measures may include, where appropriate,
                      pseudonymising and encrypting personal data, ensuring
                      confidentiality, integrity, availability and resilience of
                      our systems and services, ensuring that availability of
                      and access to personal data can be restored in a timely
                      manner after an incident, and regularly assessing and
                      evaluating the effectiveness of the technical and
                      organisational measures adopted by us). We will comply
                      with the applicable Payment Card Industry Data Security
                      Standard ("PCI DSS"). We acknowledge that we are
                      responsible for the security of cardholder data we
                      possess, or otherwise store, process or transmit on your
                      behalf and to the extent that we could impact the security
                      of your cardholder data environment;
                    </li>
                    <li>
                      assist you in ensuring compliance with your obligations
                      under the Data Protection Law with respect to security,
                      breach notifications, impact assessments and consultations
                      with supervisory authorities or regulators;
                    </li>
                    <li>
                      inform you promptly if any Customer Data is lost or
                      destroyed or becomes damaged, corrupted, or unusable or is
                      otherwise subject to unauthorised or unlawful processing,
                      including unauthorised or unlawful access or disclosure;
                    </li>
                    <li>
                      to the extent permitted by law, promptly notify you upon
                      receipt of any request from a data subject to access,
                      correct, amend, transfer or delete such person’s Customer
                      Data (including consumer requests relating to knowledge,
                      access, deletion, or opt out under the CCPA) and, at your
                      cost, provide all reasonable assistance to you in relation
                      to such request;
                    </li>
                    <li>
                      on termination of the Service, we will return, or destroy
                      (at your direction), Customer Data in our possession or
                      control unless applicable laws require the continued
                      storage of such Customer Data. Such deletion may take us
                      up to 7 days to action and, following deletion from our
                      live systems, it may take up to a further 30 days for the
                      data to be deleted from our back-ups; and
                    </li>
                    <li>
                      make available to you all information reasonably required
                      to demonstrate compliance with this section 10 and, at
                      your cost and no more than once in each 12 month period,
                      contribute to audits conducted by you or another third
                      party professional independent auditor mandated by you.
                    </li>
                  </ul>

                  <p className="not-first-child">
                    You acknowledge and agree that, as a data processor, we may
                    appoint service providers as sub-processors to process
                    Customer Data in connection with the provision of the
                    Service, in which case we will ensure that our contracts
                    with our sub-processors impose on the sub-processors
                    obligations that are equivalent to the obligations to which
                    we are subject to under this section 10 and that we will be
                    responsible for the acts and omissions of our
                    sub-processors. In some circumstances our service providers
                    may process Customer Data outside of the UK or EEA,
                    including in the US. If we engage a processor that transfers
                    Customer Data outside of the UK or EEA, we will make sure
                    appropriate safeguards such as an adequacy decision, binding
                    corporate rules or standard contractual clauses in relation
                    to the transfer are in place. You consent to the transfer of
                    Customer Data outside of the UK or EEA by our service
                    providers.
                  </p>

                  <p className="not-first-child">
                    When transactional emails (e.g. order confirmation emails)
                    are sent out to your Consumers and event attendees, your
                    account email address is specified by default as the
                    'reply-to' address in the email to make it convenient for
                    your Consumers and event attendees to reply directly to you
                    with any questions. You can change these settings in the
                    control panel if you do not wish your account email address
                    to be exposed to Consumers, but please be aware that we will
                    still direct all queries and complaints that we receive from
                    your Consumers and event attendees to you.
                  </p>
                </div>
              </div>
            </div>

            <div className="terms-service-item">
              <h3>
                12. Event Organiser content and Acceptable Content Standard
              </h3>
              <div className="terms-service-item-inner">
                <div className="terms-service-item-rht">
                  <p>
                    As between you and us, you own all rights in and have
                    responsibility for ensuring the legality, reliability,
                    integrity, accuracy and quality of all content (which may
                    include, text, pictures, information about your event and
                    associated products and services, event reviews, comment)
                    which you upload to our Website (“Your Content”). By
                    uploading Your Content, you grant us an unconditional,
                    non-exclusive, transferrable, royalty-free, worldwide
                    license to use Your Content in any manner necessary for the
                    provision of the Service.
                  </p>
                  <p className="not-first-child">
                    You have options within your account dashboard to manage
                    whether your events are published to search engines or are
                    kept private. Where an event is not private, you grant us an
                    unconditional, non-exclusive, royalty-free, worldwide
                    license to further use Your Content to promote our Service.
                  </p>
                  <p className="not-first-child">
                    You must comply with the following Acceptable Content
                    Standards when submitting Your Content to our Website:{" "}
                  </p>

                  <ul>
                    <li>
                      You must ensure Your Content is accurate and complies with
                      applicable law in any country from which it is posted; and
                    </li>
                    <li>
                      You must not use our website to collect content from
                      Consumers or event attendees which you do not need, for
                      example, Consumer card details (these should only be
                      collected by the Payment Processor), Consumer government
                      or social security numbers, Consumer passwords or any
                      other excessive or unnecessary personal data, in
                      particular special category data being information
                      revealing racial or ethnic origin, political opinions,
                      religious or similar beliefs, trade union membership,
                      physical or mental health conditions, sexual life, sexual
                      orientation, biometric or genetic data. This provision
                      does not prevent you from collecting special category data
                      to the extent this is necessary for your event eg to
                      allocate seating for people with disabilities.
                    </li>
                  </ul>

                  <p className="not-first-child">Your Content must not:</p>
                  <ul>
                    <li>
                      be unlawful, harmful, threatening, defamatory, obscene,
                      deceitful, fraudulent, infringing, harassing, morally
                      repugnant or racially or ethnically offensive;{" "}
                    </li>
                    <li>facilitate illegal activity; </li>
                    <li>depict sexually explicit images; </li>
                    <li>promote violence; </li>
                    <li>
                      be discriminatory based on race, sex, religion,
                      nationality, disability, sexual orientation or age;{" "}
                    </li>
                    <li>
                      infringe any copyright, database right, trade mark or any
                      other intellectual property right of any other person; or{" "}
                    </li>
                    <li>
                      conceal identity or be likely to deceive any person.{" "}
                    </li>
                  </ul>

                  <p className="not-first-child">
                    We may remove Your Content from our Website and Service
                    where, in our sole opinion, it violates our Acceptable
                    Content Standards. We reserve the right to suspend or
                    terminate your access to the Service if you materially
                    breach our Acceptable Content Standards, to seek
                    reimbursement from you of any costs we incur resulting from
                    your breach and to disclose information to law enforcement
                    authorities as required or as we deem reasonably necessary.
                  </p>
                </div>
              </div>
            </div>

            <div className="terms-service-item">
              <h3>13. Use of Client Logos in Marketing</h3>
              <div className="terms-service-item-inner">
                <div className="terms-service-item-rht">
                  <p>
                    By signing up and using Twotixx, you grant us the right to
                    use your logo in our marketing activities, including but not
                    limited to our website, promotional materials, and social
                    media, to indicate that you are a client. This usage does
                    not require your express permission. However, if you have
                    purchased our white label upgrade, we will not use your logo
                    for such purposes. If you prefer not to have your logo used,
                    or if you upgrade to the white label service after your logo
                    has been published, you may request its removal at any time
                    by contacting our support team.
                  </p>
                </div>
              </div>
            </div>

            <div className="terms-service-item">
              <h3>14. Disclaimer</h3>
              <div className="terms-service-item-inner">
                <div className="terms-service-item-rht">
                  <p>
                    While we endeavour to ensure that the Service is available
                    24 hours a day, we do not guarantee the availability of the
                    Service and shall not be liable if for any reason the
                    Service is unavailable at any time or for any period.
                  </p>
                  <p className="not-first-child">
                    Access to the Service may be suspended temporarily and
                    without notice in the case of system failure, maintenance or
                    repair or for reasons beyond our control.{" "}
                  </p>
                  <p className="not-first-child">
                    We do not warrant the accuracy and completeness of the
                    material on the website. We may make changes to the material
                    on the website, or to the functionality of the Service, at
                    any time without notice. The material on the website may be
                    out of date, and we make no commitment to update such
                    material.{" "}
                  </p>
                  <p className="not-first-child">
                    We will provide the Service with reasonable care and skill.
                    The Service and the material on the website is provided “as
                    is” and except for the warranties contained in these Terms,
                    to the maximum extent permitted by law, we exclude all
                    representations, warranties, conditions and other terms
                    (including, without limitation, the conditions of
                    satisfactory quality and fitness for particular purpose).{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="terms-service-item">
              <h3>15. Liability</h3>
              <div className="terms-service-item-inner">
                <div className="terms-service-item-rht">
                  <p>
                    We are not a party to any transactions, other relationships,
                    or disputes between you and Consumers or you and event
                    attendees. Furthermore, we do not pre-screen Consumers and
                    are not responsible for any fraudulent transactions by
                    Consumers. We exclude any and all liability arising out of
                    any actions that we may take in response to breaches of our
                    Acceptable Content Standards and these Terms. You agree to
                    indemnify us and our officers, directors and partners from
                    any cost, claim or demand, including reasonable legal fees:
                  </p>
                  <ul>
                    <li>
                      in relation to any dispute(s) with one or more of your
                      Consumers and/or your relationship with Consumers and
                      event attendees; and
                    </li>
                    <li>
                      made by any third party due to or arising out of Your
                      Content and your use of the Service. You are solely
                      responsible for your actions when using the Service.{" "}
                    </li>
                  </ul>
                  <p className="not-first-child">
                    Nothing in these terms shall limit or exclude our liability
                    for (a) death or personal injury caused by our negligence,
                    or the negligence of our employees, agents or
                    subcontractors; (b) fraud or fraudulent misrepresentation;
                    or (c) any other matter in respect of which it would be
                    unlawful for us to exclude or restrict liability. Subject to
                    this:
                  </p>

                  <ul>
                    <li>
                      we shall not be liable to you, whether in contract, tort
                      (including negligence), breach of statutory duty, or
                      otherwise, for any loss of profit, loss of income or any
                      indirect or consequential loss arising under or in
                      connection with the provision of our Services; and{" "}
                    </li>
                    <li>
                      our total liability to you for all other losses arising
                      under or in connection with the provision of our Services,
                      whether in contract, tort (including negligence), breach
                      of statutory duty, or otherwise, shall be limited to £100.
                    </li>
                  </ul>

                  <p className="not-first-child">
                    Nothing in these Terms seeks to limit or exclude consumers’
                    legal rights.
                  </p>
                </div>
              </div>
            </div>

            <div className="terms-service-item">
              <h3>16. Governing law and jurisdiction</h3>
              <div className="terms-service-item-inner">
                <div className="terms-service-item-rht">
                  <p>
                    These Terms shall be governed by and construed in accordance
                    with English law. Disputes arising in connection with these
                    Terms shall be subject to the non-exclusive jurisdiction of
                    the English courts.
                  </p>
                </div>
              </div>
            </div>

            <div className="terms-service-item">
              <h3>Appendix</h3>
              <div className="terms-service-item-inner">
                <div className="terms-service-item-rht">
                  <p>
                    <span>Subject matter of the processing</span> - The
                    processing of Customer Data to the extent necessary for the
                    provision of the Service to you.
                  </p>

                  <p className="not-first-child">
                    <span>Duration of the processing</span> - The period of time
                    during which you continue to use the Service and the longer
                    of such additional period as: (i) is specified in any
                    provisions of these Terms regarding data retention; and (ii)
                    is required for compliance with law.
                  </p>
                  <p className="not-first-child">
                    <span>Nature and purpose of the processing</span> - The
                    nature of the data processing is the provision of cloud
                    based service to allow Event Organisers to manage the sale
                    of tickets and associated products and services for their
                    events. The purpose of the processing is to provide the
                    Service to the Event Organiser.
                  </p>
                  <p className="not-first-child">
                    <span>Personal information types</span> - The personal
                    information processed under these Terms will depend on the
                    personal information that Event Organisers choose to collect
                    from Consumers and event attendees. Event Organisers must
                    make sure that they comply with our Acceptable Content
                    Standards in collecting personal data. Personal data is
                    likely to include: name, home address, email address,
                    telephone number, limited details relating to card payments
                    and transaction details. It may also include certain special
                    category data if the collection of such data is required in
                    relation to an event.
                  </p>
                  <p className="not-first-child">
                    <span>Categories of data subjects</span> - Personal
                    information related to your customers and event attendees.
                  </p>
                </div>
              </div>
            </div>

            {/* <div className="termBottom-content">
              <p>
                By using this website, you acknowledge that you have read,
                understood, and agree to be bound by these terms and conditions.
                If you do not agree, please do not use this website.
              </p>
            </div> */}
          </div>
          <div className="terms-service-inner-rht">
            <img
              className="terms-service-img"
              alt=""
              src="/term-service-img.svg"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Terms;
