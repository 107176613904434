import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import "./EventDetails.css";
import { FileDrop } from "react-file-drop";
import {
  BtnBold,
  BtnItalic,
  Editor,
  EditorProvider,
  Toolbar,
  BtnBulletList,
  BtnClearFormatting,
  BtnUnderline,
  createButton,
} from "react-simple-wysiwyg";
import { ReactComponent as GalleryAddFilled } from "../../../assets/svgs/GalleryAddFilled.svg";
import { ReactComponent as BinFilled } from "../../../assets/svgs/BinFilled.svg";
import { ReactComponent as ErrorFilled } from "../../../assets/svgs/ErrorFilled.svg";
import { ReactComponent as CloseFilledWhite } from "../../../assets/svgs/CloseFilledWhite.svg";
import { ReactComponent as CheckBoxChecked } from "../../../assets/svgs/CheckBoxChecked.svg";
import { ReactComponent as CheckBoxUncheck } from "../../../assets/svgs/CheckBoxUncheck.svg";
import TooltipPopup from "../../../Component/Common/TooltipPopup/TooltipPopup";
import HoverBGButton from "../../../Component/Common/Buttons/HoverBGButton/HoverBGButton";
import InputField from "../../../Component/Common/InputFields/InputFieldPrimary/InputFieldPrimary";
import ButtonPrimary from "../../../Component/Common/Buttons/ButtonPrimary/ButtonPrimary";
import DropdownInput from "../../../Component/Common/DropdownInput/DropdownInput";
import ButtonPill from "../../Common/Buttons/ButtonPill";
import Text from "../../../Component/Common/Text";
import { getVenueList } from "../../../services/api";

export default function EventDetails({
  onEventNameChange,
  onEventDescriptionChange,
  onEventDescriptionAppChange,
  onBannerImageChange,
  onEventCategoryChange,
  // onCapacityChange,
  onEventCurrencyChange,
  onPrivateEventChange = () => {},
  shouldShowSubmitButton,
  onSaveClicked,
  onDiscardClicked,
  toHaveAccessibleErrorMessage,
  eventName,
  eventDescription,
  eventDescriptionApp = "",
  bannerImage,
  eventCategory,
  capacity,
  eventCurrency,
  forVenue,
  isPrivateEvent = false,
  onRemoveCategoryClicked = () => {},
  onAddAnotherCategoryClicked = () => {},
  showAddAnotherCategory,
  forNonActiveDraftEvent = false,
  isEventPublished = false,
}) {
  const BtnAlignCenter = createButton("Align center", "≡", "justifyCenter");
  const nameInputRef = useRef(null);
  const categoryList = useSelector(
    (state) => state.eventCategoryData?.eventCategoryData
  );
  const [venueList, setVenueList] = useState([]);
  const [isSearchVenueActive, setIsSearchVenueActive] = useState(false);

  const currencyList = useSelector((state) => state.currencyData?.currencyData);

  const remainingCharCount = eventDescription.length;
  const remainingCharCountDescApp = eventDescriptionApp.length;
  const hiddenFileInput = useRef(null);
  const dragInputRef = useRef(null);

  useEffect(() => {
    if (eventName !== "" && eventName.length > 2) {
      debouncedGetVenueList();
      setIsSearchVenueActive(true);
    } else {
      setIsSearchVenueActive(false);
    }

    return () => {
      debouncedGetVenueList.cancel();
    };
  }, [eventName]);

  const getVenueListRequest = async () => {
    try {
      const result = await getVenueList({
        eventName: eventName,
      });
      if (result.status) {
        setVenueList(result.response);
      } else {
        setVenueList([]);
      }
    } catch (error) {
      console.error("Error fetching entered list: ", error);
    }
  };

  const debouncedGetVenueList = debounce(getVenueListRequest, 300);

  const onDragInputChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      onBannerImageChange(URL.createObjectURL(event.target.files[0]));
    }
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    convertAndSetLogo(event, fileUploaded);
    if (event.target.files && event.target.files[0]) {
      onBannerImageChange(URL.createObjectURL(event.target.files[0]));
    }
    event.target.value = null;
  };

  const convertAndSetLogo = (e, file) => {
    e.preventDefault();
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result;
    };
    reader.readAsDataURL(file);
  };

  let namesToRemove =
    eventCategory?.length > 0 && new Set(eventCategory?.map((v) => v));

  const renderVenueList = ({ items, index }) => {
    return (
      <div
        className="venueList-inner-widthScroll"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div className="venue-list-line-item-container" key={index}>
          <div
            className="venue-list-line-item-inner"
            style={{
              marginRight: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
            onClick={() => {
              onEventNameChange(items.name);
              onEventDescriptionChange(items.description);
              // onCapacityChange(items.capacity);
              setVenueList([]);
              setIsSearchVenueActive(false);
            }}
          >
            <Text
              style={{ cursor: "pointer" }}
              onClick={() => {}}
              color={"var(--midnightBlue)"}
              variant={"m200"}
            >
              {items.name}
            </Text>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="event-about-div SummaryLabeledDivEdit">
      <div
        className="EventDetailsTopTitle"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="generic-common-row-div">
          <Text variant={"m500"}>About</Text>
          <span className="AboutInfoIconCont">
            <TooltipPopup />
          </span>
        </div>
      </div>

      <div
        className="CreateEventEventTopLeft"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          class="event-form-div"
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: 40,
            //flex: 1 / 2,
            width: "50%",
            paddingRight: 32,
          }}
        >
          <div style={{ position: "relative" }} className="CommonFormGroup">
            <Text variant="m300" marginBottom={8}>
              {forVenue ? "Venue name*" : "Event name*"}
            </Text>
            <div style={{ position: "relative" }}>
              <InputField
                style={{ paddingRight: eventName === "" ? 6 : 36 }}
                inputRef={nameInputRef}
                inputValue={eventName}
                verifyNotNeed={true}
                shouldShowErrorOnAssistive={true}
                placeholder={forVenue ? "Enter venue name" : "Enter event name"}
                secureTextEntry={false}
                editable={true}
                type={"eventName"}
                withOutIcon={true}
                isError={false}
                onChangeValue={(text) => {
                  onEventNameChange(text);
                  setIsSearchVenueActive(true);
                }}
                onBlur={(value) => setIsSearchVenueActive(false)}
                assistive={
                  toHaveAccessibleErrorMessage?.includes("name")
                    ? "*Event name is required"
                    : toHaveAccessibleErrorMessage?.includes("venueName")
                    ? "*Venue name is required"
                    : ""
                }
              />
              {eventName && forVenue && (
                <button
                  onClick={() => {
                    onEventNameChange("");
                    nameInputRef?.current?.focus();
                    setVenueList([]);
                    setIsSearchVenueActive(false);
                  }}
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: "12px",
                    transform: "translateY(-50%)",
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    fontSize: "16px",
                    color: "#000",
                  }}
                >
                  <CloseFilledWhite width={24} height={24} />
                </button>
              )}
            </div>
            {forVenue && venueList.length > 0 && isSearchVenueActive ? (
              <div className="venue-list-item-parent-content">
                {venueList.map((items, index) => {
                  return renderVenueList({ items, index });
                })}
              </div>
            ) : null}
          </div>

          <div className="CommonFormGroup">
            {/* <Text variant="m300" marginBottom={8} marginTop={8}> */}
            <div
              style={{ marginBottom: 8, flex: "0 0 60%" }}
              className="generic-common-row-div"
            >
              <Text variant="m300">
                {forVenue
                  ? "Venue description*"
                  : "Event description (for Ticket Selling Website)*"}
              </Text>
              {forVenue ? null : (
                <TooltipPopup
                  label={"This is a long description for users browsing events"}
                />
              )}
            </div>

            {forVenue ? (
              <div
                style={{ position: "relative" }}
                className="event-description-input-container"
              >
                {" "}
                <textarea
                  className="event-description-top-aligned-input"
                  style={{
                    display: "flex",
                    flex: 1,
                    border: "none",
                    outline: "none",
                    // paddingLeft: 16,
                    // paddingRight: 38,
                    // paddingTop: 12,
                    // paddingBottom: 12,
                    borderRadius: 4,
                    verticalAlign: "top",
                    color: "var(--midnightBlue)",
                  }}
                  maxLength={forVenue ? 500 : 1500}
                  disabled={false}
                  type={"text"}
                  placeholder={
                    forVenue ? "Add venue description" : "Add event description"
                  }
                  aria-multiline="true"
                  autoCapitalize={"words"}
                  value={eventDescription}
                  onChange={(event) =>
                    onEventDescriptionChange(event.target.value)
                  }
                  rows={5}
                />
                {(toHaveAccessibleErrorMessage?.includes("description") ||
                  toHaveAccessibleErrorMessage?.includes(
                    "venueDescription"
                  )) && (
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 8,
                      padding: 8,
                    }}
                  >
                    <ErrorFilled />
                  </div>
                )}
              </div>
            ) : (
              // <DefaultEditor value={eventDescription} onChange={(event) =>
              //   {onEventDescriptionChange(event.target.value); console.log(event.target.value)}}
              //   />
              <EditorProvider>
                <Editor
                  style={{
                    color: "var(--midnightBlue)",
                    fontFamily: '"Poppins Regular 400", sans-serif',
                    fontSize: "16px",
                  }}
                  placeholder="Enter a brief event description for display on the event page"
                  value={eventDescription}
                  onChange={(event) => {
                    const newValue = event.target.value;
                    if (newValue.length <= 1500) {
                      onEventDescriptionChange(newValue);
                      console.log(newValue);
                    }
                  }}
                >
                  <Toolbar>
                    <BtnBold />
                    <BtnItalic />
                    <BtnUnderline />
                    <BtnBulletList />
                    <BtnClearFormatting />
                    {/* <BtnAlignCenter /> */}
                  </Toolbar>
                </Editor>
              </EditorProvider>
            )}

            <div
              style={{ marginTop: forVenue ? -4 : 4 }}
              className="generic-common-row-div-space-between"
            >
              <Text
                variant={"label"}
                color={getComputedStyle(
                  document.documentElement
                ).getPropertyValue("--red")}
              >
                {toHaveAccessibleErrorMessage?.includes("description")
                  ? "*Event description is required"
                  : toHaveAccessibleErrorMessage?.includes("venueDescription")
                  ? "*Venue description is required"
                  : ""}
              </Text>
              <div className="CharacterCount" style={{ textAlign: "right" }}>
                <Text
                  color={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue("--midBlue")}
                  variant={"label"}
                >
                  {`${remainingCharCount}/${forVenue ? 500 : 1500} characters`}
                </Text>
              </div>
            </div>

            {!forVenue ? (
              <div style={{ marginTop: 32 }} className="CommonFormGroup">
                {/* <Text variant="m300" marginBottom={8} marginTop={8}> */}
                <div
                  style={{ marginBottom: 8, flex: "0 0 60%" }}
                  className="generic-common-row-div"
                >
                  <Text variant="m300">
                    {"Event description (for Twotixx App)*"}
                  </Text>
                  <TooltipPopup
                    label={
                      "This is a short description for ticket holders which they will see in the TwoTixx mobile app"
                    }
                  />
                </div>
                <div
                  style={{ position: "relative" }}
                  className="event-description-input-container"
                >
                  {" "}
                  <textarea
                    className="event-description-top-aligned-input"
                    style={{
                      display: "flex",
                      flex: 1,
                      border: "none",
                      outline: "none",
                      // paddingLeft: 16,
                      // paddingRight: 38,
                      // paddingTop: 12,
                      // paddingBottom: 12,
                      borderRadius: 4,
                      verticalAlign: "top",
                      color: "var(--midnightBlue)",
                    }}
                    maxLength={500}
                    disabled={false}
                    type={"text"}
                    placeholder={
                      "Enter an event description for ticket holders in the Twotixx app"
                    }
                    aria-multiline="true"
                    autoCapitalize={"words"}
                    value={eventDescriptionApp}
                    onChange={(event) =>
                      onEventDescriptionAppChange(event.target.value)
                    }
                    rows={5}
                  />
                  {/* {(toHaveAccessibleErrorMessage?.includes("description") ||
                toHaveAccessibleErrorMessage?.includes("venueDescription")) && (
                <div
                  style={{ position: "absolute", top: 0, right: 8, padding: 8 }}
                >
                  <ErrorFilled />
                </div>
              )} */}
                </div>

                <div
                  style={{ marginTop: "-4px" }}
                  className="generic-common-row-div-space-between"
                >
                  <Text
                    variant={"label"}
                    color={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue("--red")}
                  >
                    {toHaveAccessibleErrorMessage?.includes("descriptionApp")
                      ? "*Event description is required"
                      : ""}
                  </Text>
                  <div
                    className="CharacterCount"
                    style={{ textAlign: "right" }}
                  >
                    <Text
                      color={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--midBlue")}
                      variant={"label"}
                    >
                      {`${remainingCharCountDescApp}/${500} characters`}
                    </Text>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        {!forVenue && (
          <div
            class="abcd-div EventPosterUploadCont"
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 40,
              //flex: 1 / 2,
              width: "50%",
              paddingLeft: 32,
              //marginBottom: 15,
            }}
          >
            <Text variant="m300" marginBottom={8}>
              Event poster*
            </Text>
            {bannerImage === "" ? (
              <FileDrop
                onDrop={(files, event) => (
                  event.preventDefault(),
                  onBannerImageChange(URL.createObjectURL(files[0]))
                )}
              >
                <input
                  onChange={onDragInputChange}
                  ref={dragInputRef}
                  type="file"
                  className="hidden"
                />
                <div
                  style={{ padding: "50px 0px" }}
                  className="banner-container banner-upload-block"
                >
                  <HoverBGButton
                    icon={<GalleryAddFilled height={24} width={24} />}
                    size={48}
                  />
                  <div
                    className="ClickToUploadCont"
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "start",
                      paddingLeft: 21,
                      paddingRight: 16,
                      marginTop: 8,
                      cursor: "pointer",
                    }}
                  >
                    <Text
                      cursor={"pointer"}
                      onClick={() => hiddenFileInput.current.click()}
                      textAlign={"center"}
                      underline={true}
                      variant="m300"
                      color={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--midBlue")}
                    >
                      Click to upload
                    </Text>
                    <Text marginLeft={4} textAlign={"center"} variant="m300">
                      or drag and drop
                    </Text>
                    {/* <Text variant="m300">and drop</Text> */}
                  </div>

                  <div className="ImgMaxReq">
                    <Text
                      color={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--grey")}
                      variant="r200"
                      marginTop={16}
                    >
                      JPG (max. 1920 x 1080px)
                    </Text>
                  </div>

                  <input
                    type="file"
                    accept="image/png, image/jpeg, image/jpg, image/webp"
                    onChange={handleChange}
                    ref={hiddenFileInput}
                    style={{ display: "none" }}
                  />
                </div>
              </FileDrop>
            ) : (
              <div className="banner-container-img">
                <img
                  content="center"
                  alt=""
                  style={{
                    // height: 240,
                    // width: "100%",
                    // borderRadius: 4,
                    // alignSelf: "stretch",
                    // objectFit: "cover",

                    width: "100%",
                    borderRadius: 4,
                    aspectRatio: 16 / 9,
                    objectFit: "cover",
                    display: "block",
                    border: "none",
                  }}
                  src={
                    forNonActiveDraftEvent
                      ? `data:image/png;base64,${bannerImage}`
                      : bannerImage
                  }
                />
                <div
                  style={{
                    position: "absolute",
                    marginRight: 16,
                    marginTop: 16,
                    alignSelf: "end",
                  }}
                >
                  <HoverBGButton
                    icon={
                      <BinFilled
                        fill={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue("--midBlue")}
                        height={16}
                        width={16}
                      />
                    }
                    size={32}
                    backgroundHover={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue("--white")}
                    onPress={() => onBannerImageChange("")}
                  />
                </div>
              </div>
            )}
            <Text
              marginTop={12}
              variant={"label"}
              alignSelf={"flex-start"}
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue("--red")}
            >
              {toHaveAccessibleErrorMessage?.includes("banner")
                ? "*Event poster is required"
                : ""}
            </Text>
          </div>
        )}
      </div>
      {
        !forVenue ? (
          <div
            className="EventCategoryCont createEvent-full-block"
            style={{
              display: "flex",
              flexDirection: "column",
              //marginTop: 8,
              paddingRight: 32,
              width: "50%",
            }}
          >
            {/* <div className="CommonFormGroup">
            <Text variant="m300">Event category*</Text>

            <DropdownInput
              eventCategory={eventCategory}
              data={categoryList}
              onSelectedItem={(value) => onEventCategoryChange(value)}
              editable={true}
            />
            <Text
              //marginTop={8}
              variant={"label"}
              alignSelf={"flex-start"}
              color={"var(--red)"}
            >
              {toHaveAccessibleErrorMessage?.includes("category")
                ? "*Event category is required"
                : ""}
            </Text>
          </div> */}

            {eventCategory?.length > 0 &&
              eventCategory.map((item, ind) => {
                return (
                  <div
                    style={{ width: "100%" }}
                    className="generic-common-column-div"
                  >
                    <div
                      style={{ width: "100%" }}
                      className="generic-common-row-div CommonFormFieldRow"
                    >
                      <div
                        class="CommonFormGroup"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          //marginTop: 32,
                          width: "50%",
                          paddingRight: 32,
                        }}
                      >
                        <Text variant="m300" marginBottom={8}>
                          Event category*
                        </Text>

                        <DropdownInput
                          style={{ minWidth: 240 }}
                          isMultiSelect={false}
                          type={"currency1"}
                          eventCategory={item || "Select an option"}
                          data={categoryList?.filter(
                            (option) => !eventCategory.includes(option.name)
                          )}
                          onSelectedItem={(value) =>
                            onEventCategoryChange(value, ind)
                          }
                          editable={true}
                        />

                        {toHaveAccessibleErrorMessage?.includes("category") ? (
                          <Text
                            marginTop={8}
                            variant={"label"}
                            alignSelf={"flex-start"}
                            color={"var(--red)"}
                          >
                            {toHaveAccessibleErrorMessage?.includes("category")
                              ? "*Event category is required"
                              : ""}
                          </Text>
                        ) : null}
                      </div>

                      {item.name !== "Select an option" && ind !== 0 && (
                        <ButtonPrimary
                          type={"error"}
                          isSecondary={true}
                          label={"Remove from event"}
                          onPress={() => onRemoveCategoryClicked(ind)}
                          // width={100}
                          style={{ marginRight: 0, marginLeft: 24 }}
                        />
                      )}
                    </div>
                    {showAddAnotherCategory &&
                      eventCategory?.length < 3 &&
                      ind === eventCategory.length - 1 &&
                      categoryList.filter(
                        (option) => !eventCategory.includes(option.name)
                      ).length > 0 && (
                        <ButtonPrimary
                          isSecondary={true}
                          label={"Add to another category"}
                          onPress={() => onAddAnotherCategoryClicked()}
                          width={294}
                          style={{ marginRight: 24, marginBottom: 24 }}
                        />
                      )}
                  </div>
                );
              })}

            <div className="CommonFormGroup">
              <div
                style={{ marginBottom: 12, flex: "0 0 60%" }}
                className="generic-common-row-div"
              >
                <Text variant="m300">{"Event Privacy"}</Text>
                <TooltipPopup
                  label={
                    "Your event will only be accessible via a private link and won’t appear on the browse events page."
                  }
                />
              </div>

              <div
                onClick={() => onPrivateEventChange(!isPrivateEvent)}
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                {isPrivateEvent ? <CheckBoxChecked /> : <CheckBoxUncheck />}
                <Text
                  textWrap={"nowrap"}
                  marginLeft={12}
                  color={"var(--midnightBlue)"}
                  variant={"m300"}
                >
                  {"Set event visibility as private"}
                </Text>
              </div>
              {isEventPublished && (
                <ButtonPill
                  style={{ marginTop: 12, padding: "8px", borderRadius: 4 }}
                  type={"partiallycompleted"}
                  varient={"small"}
                  label={
                    !isPrivateEvent
                      ? "This event has already been published, by ticking this box you are making the event visible everyone"
                      : "This event has already been published, by ticking this box you are only making the event visible to those you send the link to"
                  }
                />
              )}
            </div>

            <div className="CommonFormGroup">
              <Text variant="m300" marginBottom={8}>
                Event currency*
              </Text>

              <DropdownInput
                type={"currency"}
                eventCategory={eventCurrency}
                data={currencyList}
                onSelectedItem={(value, index, objectItem) =>
                  onEventCurrencyChange(
                    objectItem?.symbol
                      ? `${value} (${objectItem?.symbol || ""})`
                      : `${value}`,
                    objectItem?.id
                  )
                }
                editable={true}
              />

              <Text
                marginTop={6}
                variant={"label"}
                alignSelf={"flex-start"}
                color={
                  toHaveAccessibleErrorMessage?.includes("currency")
                    ? "var(--red)"
                    : "var(--grey)"
                }
              >
                {toHaveAccessibleErrorMessage?.includes("currency")
                  ? "*Event currency is required"
                  : "This will be the default currency used for tickets and add ons for this event."}
              </Text>
            </div>
          </div>
        ) : null
        // <div
        //   className="EventCapacityCont"
        //   style={{
        //     display: "flex",
        //     flexDirection: "row",
        //     justifyContent: "space-between",
        //   }}
        // >
        //   <div
        //     className="CapacityCont"
        //     style={{
        //       display: "flex",
        //       //flex: 1 / 2,
        //       flexDirection: "column",
        //       //marginTop: 32,
        //       width: "50%",
        //       paddingRight: 32,
        //     }}
        //   >
        //     <Text variant="m300" marginBottom={8}>
        //       Capacity*
        //     </Text>

        //     <InputField
        //       shouldShowErrorOnAssistive={true}
        //       isNumeric={true}
        //       inputValue={capacity}
        //       verifyNotNeed={true}
        //       placeholder={"e.g. 10000"}
        //       secureTextEntry={false}
        //       editable={true}
        //       type={"eventName"}
        //       withOutIcon={true}
        //       isError={false}
        //       onChangeValue={(text) => onCapacityChange(text)}
        //       onBlur={(value) => {}}
        //       assistive={
        //         toHaveAccessibleErrorMessage?.includes("capacity")
        //           ? "*Capacity is required"
        //           : ""
        //       }
        //     />
        //   </div>
        // </div>
      }
      {shouldShowSubmitButton && (
        <div
          className="bottom-buttons-container-edit-view"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginTop: 40,
          }}
        >
          <ButtonPrimary
            isSecondary={true}
            label={"Cancel"}
            onPress={() => onDiscardClicked()}
            width={328}
            style={{ marginRight: 24 }}
          />
          <ButtonPrimary
            label={"Save changes"}
            onPress={() => onSaveClicked()}
            width={328}
          />
        </div>
      )}
    </div>
  );
}
