import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ReactComponent as ChevronRight } from "../../../assets/svgs/ChevronRight.svg";
import Text from "../Text";
import ButtonPrimary from "../Buttons/ButtonPrimary/ButtonPrimary";
import "./MenuLink.css";

export default function MenuLink({
  labelArray,
  withCog,
  disabled,
  onMenuLinkPress,
  icon,
  style,
  btn1Label,
  btn2Label,
  btn3Label,
  btn5Label,
  btn6Label,
  btn7Label,
  btn3LabelClicked,
  btn1LabelClicked,
  btn2LabelClicked,
  btn4LabelClicked,
  btn5LabelClicked,
  btn6LabelClicked = () => {},
  btn7LabelClicked = () => {},
  showAnyButton,
  isForOrderDetails = false
}) {
  const { pathname } = useLocation();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      class="avhguyf event-details-top-innerMenu"
      style={{
        paddingTop: 40,
        display: "flex",
        flexDirection: "row",
        backgroundColor:
          pathname === "/Home" || pathname === "/Settings"
            ? null
            : getComputedStyle(document.documentElement).getPropertyValue(
                "--iceWhite"
              ),
        justifyContent: "space-between",
        ...style,
      }}
    >
      <div className="generic-common-row-div-top event-details-topMneu-lft">
        {labelArray.map((item, ind) => {
          return (
            <div
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onClick={() => onMenuLinkPress(ind)}
              style={{
                paddingLeft: ind === 0 && pathname !== "/Home" ? 24 : 0,
              }}
              className="menu-link-row-container"
            >
              {/* if component is with cog then it will show the Cog */}
              {withCog && item?.icon}
              <Text
                cursor={"pointer"}
                variant={
                  ind === labelArray.length - 1 && pathname !== "/Home"
                    ? "m300"
                    : "r300"
                }
                color={
                  (pathname === "/Home" || pathname === "/Settings") &&
                  ind === labelArray.length - 1
                    ? "var(--midBlue)"
                    : "var(--midnightBlue)"
                }
              >
                {item?.label}
              </Text>
              {ind !== labelArray.length - 1 && (
                <ChevronRight
                  style={{ marginLeft: 16, marginRight: 16 }}
                  width={16}
                  height={16}
                  stroke={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue("--midBlue")}
                />
              )}
            </div>
          );
        })}
      </div>
      <div style={{marginTop: isForOrderDetails ? 0 : 40}} className="event-details-event-scroll-container">
                  <div style={{justifyContent: 'flex-end', marginTop: '0px'}} className="event-detailsTop-btns-inner">
{btn4LabelClicked ? <ButtonPrimary
            textWrap={true}
            type={"generic"}
            isSecondary={true}
            label={'Duplicate'}
            onPress={() => btn4LabelClicked()}
            style={{ marginRight: 8, padding: "12px", height: "44px" }}
          /> : null}

{btn6Label ? <ButtonPrimary
            textWrap={true}
            type={"generic"}
            label={btn6Label}
            onPress={() => btn6LabelClicked()}
            style={{ marginRight: 8, padding: "12px", height: "44px" }}
          /> : null}

{btn7Label ? <ButtonPrimary
            textWrap={true}
            type={"generic"}
            isSecondary={true}
            label={btn7Label}
            onPress={() => btn7LabelClicked()}
            style={{ marginRight: 8, padding: "12px", height: "44px" }}
          /> : null}

        {btn3Label && (
          <ButtonPrimary
            textWrap={true}
            label={btn3Label}
            onPress={() => btn3LabelClicked()}
            style={{ marginRight: 8, padding: "12px", height: "44px" }}
          />
        )}

        {btn1Label && (
          <ButtonPrimary
            textWrap={true}
            type={"generic"}
            isSecondary={true}
            label={btn1Label}
            onPress={() => btn1LabelClicked()}
            style={{ marginRight: 8, padding: "12px", height: "44px" }}
          />
        )}

{btn5LabelClicked ? <ButtonPrimary
            textWrap={true}
            type={"generic"}
            isSecondary={true}
            label={btn5Label ? btn5Label : 'Save Changes'}
            onPress={() => btn5LabelClicked()}
            style={{ marginRight: 8, padding: "12px", height: "44px" }}
          /> : null}

        {btn2Label && (
          <ButtonPrimary
            textWrap={true}
            type={"error"}
            isSecondary={true}
            label={btn2Label}
            onPress={() => btn2LabelClicked()}
            style={{ marginRight: 24, padding: "12px", height: "44px", width: 'auto' }}
          />
        )}
      </div>
      </div>
    </div>
  );
}
