import React, { useEffect, useState } from "react";
import "./Ticket.css";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "../EventDetails/EventDetails.css";
import TooltipPopup from "../../../Component/Common/TooltipPopup/TooltipPopup";
import { ReactComponent as BinFilled } from "../../../assets/svgs/BinFilled.svg";
import { ReactComponent as AddFilled } from "../../../assets/svgs/AddFilled.svg";
import { ReactComponent as CheckBoxChecked } from "../../../assets/svgs/CheckBoxChecked.svg";
import { ReactComponent as CheckBoxUncheck } from "../../../assets/svgs/CheckBoxUncheck.svg";
import { ReactComponent as FunctionalFilled } from "../../../assets/svgs/FunctionalFilled.svg";
import InputField from "../../Common/InputFields/InputFieldPrimary/InputFieldPrimary";
import ButtonPrimary from "../../Common/Buttons/ButtonPrimary/ButtonPrimary";
import AccordionCustom from "../../Common/AccordionCustom/AccordionCustom";
import DropdownInput from "../../../Component/Common/DropdownInput/DropdownInput";
import RadioButton from "../../Common/Controls/RadioButton";
import Text from "../../Common/Text";
import StageReleases from "./StageReleases";

export default function Ticket({
  eventStartDate,
  eventStartTime,
  eventEndDate,
  eventEndTime,
  minTicketPrice,
  onTicketReleaseDateChange,
  onTicketReleaseTimeChange,
  onTicketReleaseEndDateChange,
  onTicketReleaseEndTimeChange,
  onTicketValidDateChange,
  onTicketValidTimeChange,
  onTicketValidEndDateChange,
  onTicketValidEndTimeChange,
  onTicketNameChange,
  onTicketQuantityChange,
  onTicketDescriptionChange,
  onTicketCategoryChange,
  onTicketCurrencyChange,
  onTicketPriceChange,
  onChangeSelectedAgeIndex,
  onStageOfferNameChange,
  onStageQuantityChange,
  onStageReleaseDateChange,
  onStageReleaseTimeChange,
  onStageReleaseEndDateChange,
  onStageReleaseEndTimeChange,
  onStageCurrencyChange,
  onStagePriceChange,
  onChangeActivateNextRelease,
  onChangeAutoTriggerNextRelease,
  onChangeReleaseType,
  onChangeMaxAge,
  onChangeMinAge,
  addAnotherStageRelease,
  toHaveAccessibleErrorMessage,
  shouldShowSubmitButton,
  onClinkingNewCategory,
  onSaveClicked,
  onDiscardClicked,
  onDeleteClicked,
  onDeleteStageReleaseClicked,
  ticketName,
  ticketQuantity,
  ticketSoldQuantity = 0,
  ticketDescription,
  ticketCategory,
  ticketCurrency,
  ticketPrice,
  selectedAgeIndex,
  minAge,
  maxAge,
  stageReleases,
  ticketCategoryList,
  ticketReleaseType,
  ticketReleaseDate,
  ticketReleaseTime,
  ticketReleaseEndDate,
  ticketReleaseEndTime,
  ticketValidDateStart,
  ticketValidTimeStart,
  ticketValidDateEnd,
  ticketValidTimeEnd,
  index,
  fees,
}) {
  const currencyList = useSelector((state) => state.currencyData?.currencyData);
  const categoryList = useSelector(
    (state) => state.eventCategoryData?.eventCategoryData
  );
  const [selectedReleaseDateValue, setSelectedReleaseDateValue] =
    useState(ticketReleaseDate);
  const [isDatePickerVisibleReleaseDate, setIsDatePickerVisibleReleaseDate] =
    useState(false);
  const [selectedReleaseEndDateValue, setSelectedReleaseEndDateValue] =
    useState(ticketReleaseDate);
  const [
    isDatePickerVisibleReleaseEndDate,
    setIsDatePickerVisibleReleaseEndDate,
  ] = useState(false);

  const [selectedReleaseDateValueTicket, setSelectedReleaseDateValueTicket] =
    useState(ticketValidDateStart);
  const [
    isDatePickerVisibleReleaseDateTicket,
    setIsDatePickerVisibleReleaseDateTicket,
  ] = useState(false);
  const [
    selectedReleaseEndDateValueTicket,
    setSelectedReleaseEndDateValueTicket,
  ] = useState(ticketValidDateEnd);
  const [
    isDatePickerVisibleReleaseEndDateTicket,
    setIsDatePickerVisibleReleaseEndDateTicket,
  ] = useState(false);

  const [selectedAgeOptionIndex, setSelectedAgeOptionIndex] =
    useState(selectedAgeIndex);
  const [assistiveMinAge, setAssistiveMinAge] = useState("");
  const [assistiveMaxAge, setAssistiveMaxAge] = useState("");
  const remainingCharCount = ticketDescription?.length;
  const [isMaxQuantityExceeded, setIsMaxQuantityExceeded] = useState(false);
  const [maxQuantityValue, setMaxQuantityValue] = useState(0);
  const [isFreeEventPriceSet, setIsFreeEventPriceSet] = useState("");

  const ageOptionAdult = [];
  const ageOptionChild = [];

  for (let i = 16; i <= 100; i += 1) {
    ageOptionAdult.push({ name: String(i) });
  }

  for (let i = 1; i <= 17; i += 1) {
    ageOptionChild.push({ name: String(i) });
  }

  const ageOptions = [
    {
      text: "General Admission",
      subDetails: "No age restriction required",
      value: 1,
    },
    {
      text: "Adult Admission",
      subDetails: "Age restriction required",
      value: 2,
    },
    {
      text: "Child Admission",
      subDetails: "Age restriction required",
      value: 3,
    },
  ];

  const releaseOptions = [
    {
      text: "Immediately",
      subDetails: "Release tickets for sale after publishing",
      value: 1,
    },
    {
      text: "Scheduled",
      subDetails: "Set date and time for ticket release",
      value: 2,
    },
    {
      text: "Staged release",
      subDetails: "Release tickets in phases across a set period of time",
      value: 3,
    },
  ];

  useEffect(() => {
    setSelectedAgeOptionIndex(selectedAgeIndex);
  }, [selectedAgeIndex]);

  useEffect(() => {
    setSelectedReleaseDateValue(ticketReleaseDate);
  }, [ticketReleaseDate]);

  useEffect(() => {
    setSelectedReleaseEndDateValue(ticketReleaseEndDate);
  }, [ticketReleaseEndDate]);

  useEffect(() => {
    setSelectedReleaseDateValueTicket(ticketValidDateStart);
  }, [ticketValidDateStart]);

  useEffect(() => {
    setSelectedReleaseEndDateValueTicket(ticketValidDateEnd);
    console.log("ticketValidDateEnd", ticketValidDateEnd);
  }, [ticketValidDateEnd]);

  useEffect(() => {}, [maxQuantityValue]);

  const generateTimeArray = () => {
    const timeArray = [];
    for (let hours = 0; hours < 24; hours++) {
      for (let minutes = 0; minutes < 60; minutes += 15) {
        const formattedTime = `${String(hours).padStart(2, "0")}:${String(
          minutes
        ).padStart(2, "0")}`;
        timeArray.push({ name: formattedTime });
      }
    }
    return timeArray;
  };

  const generateCustomTimeArray = (startTime) => {
    console.log("startTime", startTime);
    const [startHour, startMinute] = startTime?.split(":").map(Number);
    const timeArray = [];

    // Generate times from start time to the end of the day
    for (let hours = startHour; hours < 24; hours++) {
      for (
        let minutes = hours === startHour ? startMinute : 0;
        minutes < 60;
        minutes += 15
      ) {
        const formattedTime = `${String(hours).padStart(2, "0")}:${String(
          minutes
        ).padStart(2, "0")}`;
        timeArray.push({ name: formattedTime });
      }
    }

    return timeArray;
  };

  const renderTicketDetails = () => {
    return (
      <div>
        <div
          className="CommonFormFieldRow"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            className="CommonFormGroup"
            style={{
              display: "flex",
              flexDirection: "column",
              //marginTop: 32,
              flex: 1 / 2,
              paddingRight: 32,
            }}
          >
            <Text variant="m300" marginBottom={8}>
              Ticket name*
            </Text>

            <InputField
              inputValue={ticketName}
              verifyNotNeed={true}
              placeholder={
                "Event ticket name (VIP, general admission, child, adult)"
              }
              secureTextEntry={false}
              editable={true}
              type={"eventName"}
              withOutIcon={true}
              isError={false}
              onChangeValue={(text) => onTicketNameChange(text)}
              onBlur={(value) => {}}
              assistive={
                toHaveAccessibleErrorMessage?.includes("ticketName")
                  ? "*Ticket name is required"
                  : ""
              }
            />
          </div>

          <div
            className="CommonFormGroup"
            style={{
              display: "flex",
              flexDirection: "column",
              //marginTop: 32,
              flex: 1 / 2,
              paddingLeft: 32,
            }}
          >
            <Text variant="m300" marginBottom={8}>
              Quantity*
            </Text>

            <InputField
              isNumeric={true}
              inputValue={ticketQuantity}
              minNumberValue={ticketSoldQuantity || false}
              verifyNotNeed={true}
              placeholder={"Enter ticket quantity"}
              secureTextEntry={false}
              editable={true}
              type={"eventName"}
              withOutIcon={true}
              isError={false}
              onChangeValue={(text) => {
                const totalOtherStageQuantities = stageReleases.reduce(
                  (acc, release) =>
                    acc + (parseInt(release?.stageQuantity, 10) || 0),
                  0
                );

                setMaxQuantityValue(
                  parseInt(text, 10) < totalOtherStageQuantities
                    ? 0
                    : parseInt(text, 10) - totalOtherStageQuantities
                );

                if (totalOtherStageQuantities > parseInt(text, 10)) {
                  setIsMaxQuantityExceeded(stageReleases.length - 1);
                } else {
                  setIsMaxQuantityExceeded(false);
                }

                onTicketQuantityChange(text);
              }}
              onBlur={(value) => {}}
              assistive={
                toHaveAccessibleErrorMessage?.includes("quantity")
                  ? "*Quantity is required"
                  : toHaveAccessibleErrorMessage?.includes("quantity2")
                  ? "*The total ticket quantity must be greater than or equal to the sum of all stage quantities."
                  : ""
              }
            />
          </div>
        </div>

        <div
          style={{ marginTop: "36px" }}
          className="generic-common-row-div-space-between"
        >
          <div
            className="CommonFormFieldRow"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              marginTop: 28,
              width: "50%",
              marginRight: "64px",
            }}
          >
            <div
              className="CommonFormGroup"
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                marginBottom: 0,
              }}
            >
              <Text variant="m300" marginBottom={8}>
                Valid From
              </Text>
              <DropdownInput
                resetRequired={true}
                showDropdown={isDatePickerVisibleReleaseDateTicket}
                onToggleDropdown={() =>
                  setIsDatePickerVisibleReleaseDateTicket(
                    !isDatePickerVisibleReleaseDateTicket
                  )
                }
                eventCategory={selectedReleaseDateValueTicket}
                data={[]}
                editable={eventStartDate === "DD/MM/YYYY" ? false : true}
                resetBtnClicked={() => {
                  setSelectedReleaseDateValueTicket("DD/MM/YYYY");
                  onTicketValidDateChange("DD/MM/YYYY");
                }}
                customRender={() => {
                  return (
                    <div style={{ marginBottom: -10 }}>
                      <DatePicker
                        shouldCloseOnSelect={true}
                        onChange={(update) => {
                          setSelectedReleaseDateValueTicket(
                            new Date(update).toLocaleDateString("en-GB")
                          );
                          onTicketValidDateChange(
                            new Date(update).toLocaleDateString("en-GB")
                          );
                          setIsDatePickerVisibleReleaseDateTicket(
                            !isDatePickerVisibleReleaseDateTicket
                          );
                        }}
                        startDate={new Date()}
                        inline
                        minDate={
                          // new Date(
                          //   eventStartDate.split("/")[2],
                          //   eventStartDate.split("/")[1] - 1,
                          //   eventStartDate.split("/")[0]
                          // ) || null
                          null
                        }
                        // maxDate={
                        //   eventEndDate
                        //     ? new Date(
                        //         eventEndDate.split("/")[2],
                        //         eventEndDate.split("/")[1] - 1,
                        //         eventEndDate.split("/")[0]
                        //       )
                        //     : null
                        // }
                      />
                    </div>
                  );
                }}
              />
            </div>
            <div
              className="CommonFormGroup"
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                marginLeft: 24,
                marginBottom: 0,
              }}
            >
              <Text variant="m300" marginBottom={8}>
                Time
              </Text>
              <DropdownInput
                eventCategory={ticketValidTimeStart}
                data={
                  selectedReleaseDateValueTicket === eventStartDate
                    ? generateCustomTimeArray(eventStartTime)
                    : generateTimeArray()
                }
                onSelectedItem={(value) => {
                  onTicketValidTimeChange(value);
                }}
                editable={
                  selectedReleaseDateValueTicket === "DD/MM/YYYY" ? false : true
                }
              />
            </div>
          </div>

          <div
            className="CommonFormFieldRow"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              marginTop: 28,
              width: "50%",
            }}
          >
            <div
              className="CommonFormGroup"
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                marginBottom: 0,
              }}
            >
              <Text variant="m300" marginBottom={8}>
                Valid Until
              </Text>
              <DropdownInput
                resetRequired={true}
                showDropdown={isDatePickerVisibleReleaseEndDateTicket}
                onToggleDropdown={() =>
                  setIsDatePickerVisibleReleaseEndDateTicket(
                    !isDatePickerVisibleReleaseEndDateTicket
                  )
                }
                eventCategory={
                  (selectedReleaseEndDateValueTicket === "DD/MM/YYYY" ||
                    selectedReleaseDateValueTicket === "DD/MM/YYYY" ||
                    selectedReleaseEndDateValueTicket.split("/")[2] ===
                      "9999") &&
                  eventEndDate !== 'DD/MM/YYYY'
                    ? eventEndDate
                    : selectedReleaseEndDateValueTicket
                }
                data={[]}
                editable={
                  selectedReleaseDateValueTicket === "DD/MM/YYYY" ||
                  ticketValidTimeStart === "00:00 "
                    ? false
                    : true
                }
                resetBtnClicked={() => {
                  setSelectedReleaseEndDateValueTicket("DD/MM/YYYY");
                  onTicketValidEndDateChange("DD/MM/YYYY");
                }}
                customRender={() => {
                  return (
                    <div style={{ marginBottom: -10 }}>
                      <DatePicker
                        shouldCloseOnSelect={true}
                        onChange={(update) => {
                          setSelectedReleaseEndDateValueTicket(
                            new Date(update).toLocaleDateString("en-GB")
                          );
                          onTicketValidEndDateChange(
                            new Date(update).toLocaleDateString("en-GB")
                          );
                          setIsDatePickerVisibleReleaseEndDateTicket(
                            !isDatePickerVisibleReleaseEndDateTicket
                          );
                        }}
                        startDate={new Date()}
                        inline
                        // maxDate={
                        //   eventEndDate
                        //     ? new Date(
                        //         eventEndDate.split("/")[2],
                        //         eventEndDate.split("/")[1] - 1,
                        //         eventEndDate.split("/")[0]
                        //       )
                        //     : null
                        // }
                        minDate={
                          new Date(
                            selectedReleaseDateValueTicket.split("/")[2],
                            selectedReleaseDateValueTicket.split("/")[1] - 1,
                            selectedReleaseDateValueTicket.split("/")[0]
                          ) || null
                        }
                        // minDate={new Date()}
                      />
                    </div>
                  );
                }}
              />
            </div>
            <div
              className="CommonFormGroup"
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                marginLeft: 24,
                marginBottom: 0,
              }}
            >
              <Text variant="m300" marginBottom={8}>
                Time
              </Text>
              <DropdownInput
                eventCategory={
                  (selectedReleaseEndDateValueTicket === "DD/MM/YYYY" ||
                    selectedReleaseDateValueTicket === "DD/MM/YYYY" ||
                    selectedReleaseEndDateValueTicket.split("/")[2] ===
                      "9999") &&
                  eventEndTime !== "00:00"
                    ? eventEndTime
                    : ticketValidTimeEnd
                }
                data={
                  selectedReleaseDateValueTicket ===
                  selectedReleaseEndDateValueTicket
                    ? generateCustomTimeArray(ticketValidTimeStart)
                    : generateTimeArray()
                }
                onSelectedItem={(value) => {
                  onTicketValidEndTimeChange(value);
                }}
                editable={
                  selectedReleaseEndDateValueTicket === "DD/MM/YYYY"
                    ? false
                    : true
                }
              />
            </div>
          </div>
        </div>
        {/* <Text color={"var(--textDisabled)"} variant={"r300"}> */}
        <Text
          marginTop={8}
          variant={"label"}
          alignSelf={"flex-start"}
          color={"var(--textDisabled)"}
        >
          {
            "Please ensure you enter the start and end date and time this ticket is valid for.  If you leave this blank then the ticket will be valid for the full duration of the event"
          }
        </Text>

        <div
          style={{ alignItems: "flex-start" }}
          className="generic-common-row-div-space-between CommonFormFieldRow"
        >
          <div
            className="CommonFormGroup"
            style={{
              display: "flex",
              flexDirection: "column",
              //marginTop: 32,
              flex: 1 / 2,
              paddingRight: 32,
            }}
          >
            <Text variant="m300" marginBottom={8}>
              {"Ticket description*"}
            </Text>
            <div className="ticket-input-container">
              <textarea
                className="ticket-top-aligned-input"
                style={{
                  display: "flex",
                  flex: 1,
                  border: "none",
                  outline: "none",
                  paddingLeft: 16,
                  paddingTop: 12,
                  borderRadius: 4,
                  verticalAlign: "top",
                  color: "var(--midnightBlue)",
                }}
                maxLength={400}
                type={"text"}
                placeholder={"Enter ticket description"}
                aria-multiline="true"
                autoCapitalize={"words"}
                value={ticketDescription}
                // onChange={(event) =>
                //   onTicketDescriptionChange(event.target.value)
                // }
                onChange={(event) => {
                  const cleanedValue = event.target.value?.replace(
                    /\u2022\s?/g,
                    ""
                  );
                  onTicketDescriptionChange(cleanedValue);
                }}
                rows={5}
              />
            </div>
            <div
              style={{ marginTop: 2 }}
              className="generic-common-row-div-space-between"
            >
              <Text
                variant={"label"}
                alignSelf={"flex-start"}
                color={"var(--red)"}
              >
                {toHaveAccessibleErrorMessage?.includes("description")
                  ? "*Ticket description is required"
                  : ""}
              </Text>
              <Text
                color={getComputedStyle(
                  document.documentElement
                ).getPropertyValue("--midBlue")}
                alignSelf={"flex-end"}
                variant={"label"}
              >
                {remainingCharCount}/400 characters
              </Text>
            </div>
          </div>
          <div
            className="CommonFormGroup"
            style={{
              display: "flex",
              flexDirection: "column",
              //marginTop: 32,
              flex: 1 / 2,
              paddingLeft: 32,
            }}
          >
            <Text variant="m300" marginBottom={8}>
              Ticket category*
            </Text>

            <DropdownInput
              type={"ticketCategory"}
              eventCategory={ticketCategory}
              data={ticketCategoryList}
              onSelectedItem={(value) => onTicketCategoryChange(value)}
              editable={ticketCategoryList?.length === 0 ? false : true}
            />
            <Text
              marginTop={6}
              variant={"label"}
              alignSelf={"flex-start"}
              color={"var(--red)"}
            >
              {toHaveAccessibleErrorMessage?.includes("category")
                ? "*Ticket category is required"
                : ""}
            </Text>

            {/* <ButtonPrimary
              onPress={() => onClinkingNewCategory()}
              style={{
                marginTop: toHaveAccessibleErrorMessage?.includes("category")
                  ? 8
                  : 24,
              }}
              icon={<AddFilled />}
              showLeftIcon={true}
              isSecondary={true}
              width={188}
              label={"Create category"}
            /> */}
          </div>
        </div>

        <div
          style={{ alignItems: "flex-start" }}
          className="generic-common-row-div-space-between CommonFormFieldRow"
        >
          {/* <div
            className="CommonFormGroup"
            style={{
              display: "flex",
              flexDirection: "column",
              //marginTop: 32,
              flex: 1 / 2,
              paddingRight: 32,
            }}
          >
            <Text color={"var(--grey)"} variant="m300" marginBottom={8}>
              Currency
            </Text>

            <DropdownInput
              type={"currency"}
              eventCategory={ticketCurrency}
              data={currencyList}
              onSelectedItem={(value) => onTicketCurrencyChange(value)}
              editable={false}
            />

            <Text
              marginTop={6}
              variant={"label"}
              alignSelf={"flex-start"}
              color={"var(--red)"}
            >
              {toHaveAccessibleErrorMessage?.includes("currency")
                ? "*Currency is required"
                : ""}
            </Text>
          </div> */}
        </div>
      </div>
    );
  };

  const renderAgeRestrictions = () => {
    return (
      <div>
        <Text variant={"m300"}>
          {"Which category below applies to this ticket?"}
        </Text>
        <RadioButton
          styles={{ marginTop: 20, marginBottom: 8 }}
          marginRight={32}
          vertically={false}
          data={ageOptions}
          selectIndex={selectedAgeOptionIndex}
          onSelect={(item, index) => {
            onChangeSelectedAgeIndex(index);
            setSelectedAgeOptionIndex(index);
          }}
        />

        <Text variant={"label"} color={"var(--red)"} alignSelf={"center"}>
          {toHaveAccessibleErrorMessage?.includes("selectedAgeOptionIndex")
            ? "Please select an age restrictions"
            : ""}
        </Text>

        {selectedAgeOptionIndex ? (
          <div
            style={{ paddingBottom: 16, marginTop: 28 }}
            className="generic-common-row-div-top"
          >
            <div
              className="MinimumAgeDropdown"
              style={{
                display: "flex",
                flexDirection: "column",
                width: "50%",
              }}
            >
              <Text variant="m300" marginBottom={8}>
                Minimum age
              </Text>
              <DropdownInput
                eventCategory={!minAge || minAge === "" ? "Select age" : minAge}
                data={
                  selectedAgeOptionIndex === 1 ? ageOptionAdult : ageOptionChild
                }
                onSelectedItem={(value) => {
                  function removeError() {
                    setAssistiveMaxAge("");
                    setAssistiveMinAge("");
                  }

                  function showError() {
                    setAssistiveMaxAge("");
                    setAssistiveMinAge(
                      "Minimum age must be lesser than maximum age"
                    );
                  }
                  onChangeMinAge(value);
                  maxAge !== "" && parseInt(maxAge) < parseInt(value)
                    ? showError()
                    : removeError();
                }}
                editable={true}
                assistive={
                  assistiveMinAge === "" &&
                  minAge !== "" &&
                  toHaveAccessibleErrorMessage?.includes("minAge")
                    ? "*Minimum age is required"
                    : assistiveMinAge
                }
              />
            </div>
            {selectedAgeOptionIndex === 2 ? (
              <div
                className="MinimumAgeDropdown"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "50%",
                  marginLeft: 32,
                }}
              >
                <Text variant="m300" marginBottom={8}>
                  Maximum age (Optional)
                </Text>
                <DropdownInput
                  eventCategory={
                    !maxAge || maxAge === "" ? "Select age" : maxAge
                  }
                  data={ageOptionChild}
                  onSelectedItem={(value) => {
                    function removeError() {
                      setAssistiveMaxAge("");
                      setAssistiveMinAge("");
                    }

                    function showError() {
                      setAssistiveMinAge("");
                      setAssistiveMaxAge(
                        "Maximum age must be greater than minimum age"
                      );
                    }

                    onChangeMaxAge(value);
                    minAge !== "" && parseInt(minAge) > parseInt(value)
                      ? showError()
                      : removeError();
                  }}
                  editable={true}
                  assistive={
                    assistiveMaxAge === "" &&
                    maxAge !== "" &&
                    toHaveAccessibleErrorMessage?.includes("maxAge")
                      ? "*Maximum age is required"
                      : assistiveMaxAge
                  }
                />
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  };

  const renderTicketRelease = () => {
    return (
      <div>
        <Text variant={"m300"}>How do you want to release this ticket?</Text>

        <RadioButton
          styles={{ marginTop: 16 }}
          marginRight={32}
          vertically={false}
          data={releaseOptions}
          selectIndex={
            ticketReleaseType === "automatic"
              ? 0
              : ticketReleaseType === "manual"
              ? 1
              : 2
          }
          onSelect={(item, index) => {
            onChangeReleaseType(
              index === 0 ? "automatic" : index === 1 ? "manual" : "staged"
            );
          }}
        />

        <div
          className="generic-common-row-div-space-between-not-centered"
           style={{ marginTop: -16 }}
        >
          {ticketReleaseType === "automatic" ? (
            <Text color={"var(--newTextBody)"} variant={"r100"}>
              If you need to stop ticket sales early, then you can set the date manually.
            </Text>
          ) : null}

          <Text variant={"label"} color={"var(--red)"}>
            {toHaveAccessibleErrorMessage?.includes("selectLeastOneStage")
              ? "Please add atleast one stage release to proceed."
              : ""}
          </Text>
        </div>

        {/* <div style={{marginTop: 16}} className="generic-common-row-div">
          <div
            onClick={() => {
              onChangeReleaseType("automatic");
            }}
            style={{ height: 36, width: "50%" }}
            className="generic-common-row-div central-modal-contentPopup-check"
          >
            <div
              style={{
                width: 38,
                height: 38,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              {ticketReleaseType === "automatic" ? (
                <CheckBoxCheckedCircle width={24} height={24} />
              ) : (
                <CheckBoxUncheckCircle width={24} height={24} />
              )}
            </div>
            <Text marginTop={-2} marginLeft={4} variant={"r200"}>
              Automatically release tickets for sale after publishing
            </Text>
          </div>

          <div
            onClick={() => {
              onChangeReleaseType("manual");
            }}
            style={{ width: "50%", height: 36 }}
            className="generic-common-row-div central-modal-contentPopup-check"
          >
            <div
              style={{
                width: 38,
                height: 38,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              {ticketReleaseType === "manual" ? (
                <CheckBoxCheckedCircle width={24} height={24} />
              ) : (
                <CheckBoxUncheckCircle width={24} height={24} />
              )}
            </div>
            <Text marginTop={-2} marginLeft={4} variant={"r200"}>
              Manually set date and time for ticket release
            </Text>
          </div>
        </div> */}

        {ticketReleaseType === "manual" && (
          <div
            style={{ marginTop: 0 }}
            className="generic-common-row-div-space-between"
          >
            <div
              className="CommonFormFieldRow"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                marginTop: 28,
                width: "50%",
                marginRight: "64px",
              }}
            >
              <div
                className="CommonFormGroup"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  marginBottom: 0,
                }}
              >
                <Text variant="m300" marginBottom={8}>
                  Start release date*
                </Text>
                <DropdownInput
                  resetRequired={true}
                  showDropdown={isDatePickerVisibleReleaseDate}
                  onToggleDropdown={() =>
                    setIsDatePickerVisibleReleaseDate(
                      !isDatePickerVisibleReleaseDate
                    )
                  }
                  eventCategory={selectedReleaseDateValue}
                  data={[]}
                  editable={eventStartDate === "DD/MM/YYYY" ? false : true}
                  resetBtnClicked={() => {
                    setSelectedReleaseDateValue("DD/MM/YYYY");
                    onTicketReleaseDateChange("DD/MM/YYYY");
                  }}
                  customRender={() => {
                    return (
                      <div style={{ marginBottom: -10 }}>
                        <DatePicker
                          shouldCloseOnSelect={true}
                          onChange={(update) => {
                            setSelectedReleaseDateValue(
                              new Date(update).toLocaleDateString("en-GB")
                            );
                            onTicketReleaseDateChange(
                              new Date(update).toLocaleDateString("en-GB")
                            );
                            setIsDatePickerVisibleReleaseDate(
                              !isDatePickerVisibleReleaseDate
                            );
                          }}
                          startDate={new Date()}
                          inline
                          minDate={
                            // new Date(
                            //   eventStartDate.split("/")[2],
                            //   eventStartDate.split("/")[1] - 1,
                            //   eventStartDate.split("/")[0]
                            // ) || null
                            null
                          }
                        />
                      </div>
                    );
                  }}
                />
                <Text
                  marginTop={8}
                  variant={"label"}
                  alignSelf={"flex-start"}
                  color={"var(--red)"}
                >
                  {toHaveAccessibleErrorMessage?.includes("releaseDate")
                    ? "*Start release date is required"
                    : ""}
                </Text>
              </div>
              <div
                className="CommonFormGroup"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  marginLeft: 24,
                  marginBottom: 0,
                }}
              >
                <Text variant="m300" marginBottom={8}>
                  Time*
                </Text>
                <DropdownInput
                  eventCategory={ticketReleaseTime}
                  data={
                    selectedReleaseDateValue === eventStartDate
                      ? generateCustomTimeArray(eventStartTime)
                      : generateTimeArray()
                  }
                  onSelectedItem={(value) => {
                    onTicketReleaseTimeChange(value);
                  }}
                  editable={
                    selectedReleaseDateValue === "DD/MM/YYYY" ? false : true
                  }
                />
                <Text
                  marginTop={8}
                  variant={"label"}
                  alignSelf={"flex-start"}
                  color={"var(--red)"}
                >
                  {toHaveAccessibleErrorMessage?.includes("releaseTime")
                    ? "*Start release time is required"
                    : ""}
                </Text>
              </div>
            </div>
            <div
              className="CommonFormFieldRow"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                marginTop: 28,
                width: "50%",
              }}
            >
              <div
                className="CommonFormGroup"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  marginBottom: 0,
                }}
              >
                <Text variant="m300" marginBottom={8}>
                  End release date*
                </Text>
                <DropdownInput
                  resetRequired={true}
                  showDropdown={isDatePickerVisibleReleaseEndDate}
                  onToggleDropdown={() =>
                    setIsDatePickerVisibleReleaseEndDate(
                      !isDatePickerVisibleReleaseEndDate
                    )
                  }
                  eventCategory={selectedReleaseEndDateValue}
                  data={[]}
                  editable={
                    selectedReleaseDateValue === "DD/MM/YYYY" ||
                    ticketReleaseTime === "00:00 "
                      ? false
                      : true
                  }
                  resetBtnClicked={() => {
                    setSelectedReleaseEndDateValue("DD/MM/YYYY");
                    onTicketReleaseEndDateChange("DD/MM/YYYY");
                  }}
                  customRender={() => {
                    return (
                      <div style={{ marginBottom: -10 }}>
                        <DatePicker
                          shouldCloseOnSelect={true}
                          onChange={(update) => {
                            setSelectedReleaseEndDateValue(
                              new Date(update).toLocaleDateString("en-GB")
                            );
                            onTicketReleaseEndDateChange(
                              new Date(update).toLocaleDateString("en-GB")
                            );
                            setIsDatePickerVisibleReleaseEndDate(
                              !isDatePickerVisibleReleaseEndDate
                            );
                          }}
                          startDate={new Date()}
                          inline
                          minDate={
                            new Date(
                              selectedReleaseDateValue.split("/")[2],
                              selectedReleaseDateValue.split("/")[1] - 1,
                              selectedReleaseDateValue.split("/")[0]
                            ) || null
                          }
                          // minDate={new Date()}
                        />
                      </div>
                    );
                  }}
                />
                <Text
                  marginTop={8}
                  variant={"label"}
                  alignSelf={"flex-start"}
                  color={"var(--red)"}
                >
                  {toHaveAccessibleErrorMessage?.includes("releaseEndDate")
                    ? "*End release date is required"
                    : ""}
                </Text>
              </div>
              <div
                className="CommonFormGroup"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  marginLeft: 24,
                  marginBottom: 0,
                }}
              >
                <Text variant="m300" marginBottom={8}>
                  Time*
                </Text>
                <DropdownInput
                  eventCategory={ticketReleaseEndTime}
                  data={
                    selectedReleaseDateValue === selectedReleaseEndDateValue
                      ? generateCustomTimeArray(ticketReleaseTime)
                      : generateTimeArray()
                  }
                  onSelectedItem={(value) => {
                    onTicketReleaseEndTimeChange(value);
                  }}
                  editable={
                    selectedReleaseEndDateValue === "DD/MM/YYYY" ? false : true
                  }
                />
                <Text
                  marginTop={8}
                  variant={"label"}
                  alignSelf={"flex-start"}
                  color={"var(--red)"}
                >
                  {toHaveAccessibleErrorMessage?.includes("releaseEndTime")
                    ? "*End release time is required"
                    : ""}
                </Text>
              </div>
            </div>
          </div>
        )}

        {ticketReleaseType !== "staged" ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              marginTop: "28px",
            }}
          >
            <Text variant="m300" marginBottom={16}>
              How would you like to price this ticket?
            </Text>

            <div
              style={{
                alignItems: "flex-start",
                marginTop: 0,
                width: "50%",
                paddingLeft: 0,
                paddingRight: 64,
              }}
              className="generic-common-row-div-space-between ticket-price-parent-box"
            >
              <div
                className="CommonFormGroup"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                <Text variant="b200" marginBottom={8}>
                  Ticket price*
                </Text>

                <InputField
                  prefix={
                    ticketCurrency !== "-" && (ticketPrice || isFreeEventPriceSet) ? ticketCurrency : ""
                  }
                  isNumeric={true}
                  inputValue={isFreeEventPriceSet ? '0.00' : ticketPrice}
                  verifyNotNeed={true}
                  placeholder={"Enter ticket price"}
                  secureTextEntry={false}
                  editable={isFreeEventPriceSet ?  false : true}
                  type={"eventName"}
                  withOutIcon={true}
                  isError={false}
                  onChangeValue={(text) => onTicketPriceChange(text)}
                  onBlur={(value) => {}}
                  assistive={
                    toHaveAccessibleErrorMessage?.includes("ticketPrice")
                      ? "*Ticket price is required"
                      : ""
                  }
                />

{!isFreeEventPriceSet ? <Text color={"var(--newTextBody)"} variant={"r100"} marginLeft={4} marginTop={8}>
                  {`Minimum price is ${extractCurrencySymbol(ticketCurrency)}${minTicketPrice} for stripe payments to be accepted.`}
                </Text> : null}


<div
                onClick={() => {
                  if(!isFreeEventPriceSet)
                  {
                    onTicketPriceChange(0)
                  }
                  setIsFreeEventPriceSet(!isFreeEventPriceSet)}}
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  cursor: "pointer",
                  marginTop: 16,
                }}
              >
                {isFreeEventPriceSet ? <CheckBoxChecked /> : <CheckBoxUncheck />}
                <Text
                  textWrap={"nowrap"}
                  marginLeft={12}
                  color={"var(--midnightBlue)"}
                  variant={"m300"}
                >
                  {"This ticket is free of charge"}
                </Text>
              </div>
              </div>
              {/* <div
            className="currencyTicket-item"
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: 4,
            }}
          >
            <DropdownInput
              width={132}
              style={{ marginTop: 28 }}
              type={"currency"}
              eventCategory={ticketCurrency}
              data={currencyList}
              onSelectedItem={(value) => onStageCurrencyChange(value, index)}
              editable={false}
            />
          </div> */}
            </div>
          </div>
        ) : null}

        {ticketReleaseType === "staged" ? (
          <div style={{ marginTop: 32 }}>{renderStagedReleases()}</div>
        ) : ticketReleaseType !== "staged" && parseInt(ticketPrice) > 0 ? (
          <div
            style={{ marginBottom: "10px" }}
            className="ticket-payment-overview-container"
          >
            <div className="generic-common-row-div-top">
              <FunctionalFilled />
              <div
                style={{ marginLeft: 16 }}
                className="generic-common-column-div"
              >
                <Text variant={"m500"}>{"Payment overview"}</Text>
                <Text marginTop={12} variant={"r300"}>
                  {
                    "Calculations are based on your chosen fee structure at the previous step."
                  }
                </Text>
              </div>
            </div>
            <Text variant="m400" marginTop={32}>
              {ticketName ? ticketName : `Ticket ${index + 1}`}
            </Text>

            <div
              style={{ width: "100%" }}
              className="generic-common-row-div FullPriceBodyCont"
            >
              <div
                className="generic-common-row-div-space-between FullPriceGroup"
                style={{
                  marginTop: 16,
                  paddingRight: 32,
                  width: "50%",
                }}
              >
                <span className="FullPriceLabel">
                  <Text variant="r300" marginBottom={8}>
                    Total price customer pays
                  </Text>
                </span>
                <span className="FullPriceDetails">
                  <Text variant="b300" marginBottom={8}>
                    {`${extractCurrencySymbol(ticketCurrency)}${parseFloat(
                      !fees?.isAddFeesToTicketPrice
                        ? parseInt(ticketPrice) +
                            fees?.platformFee +
                            (ticketPrice * fees?.platformFeePct) / 100 +
                            fees?.ticketGuardFee +
                            (fees?.isAddBookingFees
                              ? fees?.bookingPercentValue && fees?.maxBookingFee
                                ? fees?.maxBookingFee <
                                  (ticketPrice * fees?.bookingPercentValue) /
                                    100
                                  ? fees?.maxBookingFee
                                  : (ticketPrice * fees?.bookingPercentValue) /
                                    100
                                : 0
                              : 0)
                        : parseInt(ticketPrice) +
                            (fees?.isAddBookingFees
                              ? fees?.maxBookingFee <
                                (ticketPrice * fees?.bookingPercentValue) / 100
                                ? fees?.maxBookingFee
                                : (ticketPrice * fees?.bookingPercentValue) /
                                  100
                              : 0)
                    ).toFixed(2)}`}
                  </Text>
                </span>
              </div>
              <div
                className="generic-common-row-div-space-between FullPriceGroup"
                style={{
                  marginTop: 16,
                  paddingLeft: 32,
                  width: "50%",
                }}
              >
                <span className="FullPriceLabel">
                  <Text variant="r300" marginBottom={8}>
                    Amount received by organiser
                  </Text>
                </span>
                <span className="FullPriceDetails">
                  <Text variant="b300" marginBottom={8}>
                    {`${extractCurrencySymbol(ticketCurrency)}${parseFloat(
                      (!fees?.isAddFeesToTicketPrice
                        ? parseInt(ticketPrice) +
                          fees?.platformFee +
                          (ticketPrice * fees?.platformFeePct) / 100 +
                          fees?.ticketGuardFee +
                          (fees?.isAddBookingFees
                            ? fees?.maxBookingFee <
                              (ticketPrice * fees?.bookingPercentValue) / 100
                              ? fees?.maxBookingFee
                              : (ticketPrice * fees?.bookingPercentValue) / 100
                            : 0)
                        : parseInt(ticketPrice) +
                          (fees?.isAddBookingFees
                            ? fees?.maxBookingFee <
                              (ticketPrice * fees?.bookingPercentValue) / 100
                              ? fees?.maxBookingFee
                              : (ticketPrice * fees?.bookingPercentValue) / 100
                            : 0)) -
                        (fees?.platformFee +
                          (ticketPrice * fees?.platformFeePct) / 100 +
                          fees?.ticketGuardFee +
                          (fees?.isAddBookingFees
                            ? fees?.maxBookingFee <
                              (ticketPrice * fees?.bookingPercentValue) / 100
                              ? fees?.maxBookingFee * 0.2
                              : ((ticketPrice * fees?.bookingPercentValue) /
                                  100) *
                                0.2
                            : 0))
                    ).toFixed(2)}`}
                  </Text>
                </span>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  const extractCurrencySymbol = (str) => {
    const match = str.match(/\(([^)]+)\)/);
    return match ? match[1]?.trim() : str;
  };

  const renderStagedReleases = () => {
    return (
      <div>
        {stageReleases?.map((item, index) => {
          return (
            <StageReleases
              maxQuantity={isMaxQuantityExceeded === index ? true : false}
              maxQuantityValue={maxQuantityValue}
              ticketName={ticketName}
              ticketQuantity={ticketQuantity}
              eventStartDate={eventStartDate}
              eventStartTime={eventStartTime}
              stageReleases={stageReleases}
              isStageEditable={ticketReleaseType === "staged" ? true : false}
              data={item}
              index={index}
              isLastIndex={index === stageReleases.length - 1}
              onDeleteStageReleaseClicked={(ind) =>
                onDeleteStageReleaseClicked(ind)
              }
              onStageOfferNameChange={(value, ind) =>
                onStageOfferNameChange(value, ind)
              }
              onStageCurrencyChange={(value, ind) =>
                onStageCurrencyChange(value, ind)
              }
              onStageReleaseDateChange={(value, ind) =>
                onStageReleaseDateChange(value, ind)
              }
              onStageReleaseTimeChange={(value, ind) =>
                onStageReleaseTimeChange(value, ind)
              }
              onStageReleaseEndDateChange={(value, ind) =>
                onStageReleaseEndDateChange(value, ind)
              }
              onStageReleaseEndTimeChange={(value, ind) =>
                onStageReleaseEndTimeChange(value, ind)
              }
              onStageQuantityChange={(value, ind) => {
                const newStageQuantity = parseInt(value, 10) || 0;
                const totalOtherStageQuantities = stageReleases
                  .filter((_, i) => i !== ind) // Exclude the current stage being edited
                  .reduce(
                    (acc, release) =>
                      acc + (parseInt(release?.stageQuantity, 10) || 0),
                    0
                  );

                setMaxQuantityValue(
                  parseInt(ticketQuantity, 10) <
                    totalOtherStageQuantities + newStageQuantity
                    ? 0
                    : parseInt(ticketQuantity, 10) -
                        (totalOtherStageQuantities + newStageQuantity)
                );

                if (
                  totalOtherStageQuantities + newStageQuantity >
                  parseInt(ticketQuantity, 10)
                ) {
                  setIsMaxQuantityExceeded(ind);
                } else {
                  setIsMaxQuantityExceeded(false);
                }
                onStageQuantityChange(value, ind);
              }}
              onStagePriceChange={(value, ind) =>
                onStagePriceChange(value, ind)
              }
              onChangeActivateNextRelease={(value, ind) =>
                onChangeActivateNextRelease(value, ind)
              }
              onChangeAutoTriggerNextRelease={(value, ind) =>
                onChangeAutoTriggerNextRelease(value, ind)
              }
              addAnotherStageRelease={(ind) => addAnotherStageRelease(ind)}
            />
          );
        })}

        <div className="generic-common-column-centered-div">
          <ButtonPrimary
            disabled={
              ticketReleaseType === "staged" &&
              stageReleases.every((release) => {
                return (
                  (release.activateNextRelease === true ||
                    (release.stageReleaseDate !== "DD/MM/YYYY" &&
                      release.stageReleaseTime !== "-" &&
                      release.stageReleaseEndDate !== "DD/MM/YYYY" &&
                      release.stageReleaseEndTime !== "-")) &&
                  release.stageOfferName !== "-" &&
                  release.stageQuantity !== "" &&
                  release.stagePrice !== "" &&
                  parseInt(ticketQuantity, 10) >=
                    stageReleases.reduce(
                      (acc, release) =>
                        acc + (parseInt(release?.stageQuantity, 10) || 0),
                      0
                    )
                );
              })
                ? false
                : true
            }
            isSecondary={true}
            label={
              stageReleases?.length > 0 ? "Add another release" : "Add release"
            }
            onPress={() => addAnotherStageRelease(stageReleases?.length - 1)}
            width={328}
            style={{ marginTop: stageReleases?.length > 0 ? 32 : -16 }}
          />

          {toHaveAccessibleErrorMessage?.includes("stageReleasesCountZero") ? (
            <Text
              marginTop={8}
              variant={"label"}
              color={"var(--red)"}
              alignSelf={"center"}
            >
              {"Please add atleast one stage release to proceed."}
            </Text>
          ) : null}
        </div>

        {stageReleases.some(
          (item) =>
            item?.stageOfferName?.trim() !== "" &&
            parseInt(item?.stagePrice) > 0
        ) && (
          <div className="ticket-payment-overview-container">
            <div className="generic-common-row-div-top">
              <FunctionalFilled />
              <div
                style={{ marginLeft: 16 }}
                className="generic-common-column-div"
              >
                <Text variant={"m500"}>{"Payment overview"}</Text>
                <Text marginTop={12} variant={"r300"}>
                  {
                    "Calculations are based on your chosen fee structure at the previous step."
                  }
                </Text>
              </div>
            </div>

            {stageReleases.map((item, index) => {
              return (
                item?.stageOfferName?.trim() !== "" &&
                parseInt(item?.stagePrice) > 0 && (
                  <div style={{ marginTop: 32, width: "100%" }}>
                    <Text variant="m400">{`Staged release (${item?.stageOfferName})`}</Text>
                    <div
                      style={{ width: "100%" }}
                      className="generic-common-row-div FullPriceBodyCont"
                    >
                      <div
                        className="generic-common-row-div-space-between FullPriceGroup"
                        style={{
                          marginTop: 16,
                          paddingRight: 32,
                          width: "50%",
                        }}
                      >
                        <span className="FullPriceLabel">
                          <Text variant="r300" marginBottom={8}>
                            Total price customer pays
                          </Text>
                        </span>
                        <span className="FullPriceDetails">
                          <Text variant="b300" marginBottom={8}>
                            {`${extractCurrencySymbol(
                              ticketCurrency
                            )}${parseFloat(
                              !fees?.isAddFeesToTicketPrice
                                ? parseInt(item?.stagePrice) +
                                    fees?.platformFee +
                                    (item?.stagePrice * fees?.platformFeePct) /
                                      100 +
                                    fees?.ticketGuardFee +
                                    (fees?.isAddBookingFees
                                      ? fees?.bookingPercentValue &&
                                        fees?.maxBookingFee
                                        ? fees?.maxBookingFee <
                                          (item?.stagePrice *
                                            fees?.bookingPercentValue) /
                                            100
                                          ? fees?.maxBookingFee
                                          : (item?.stagePrice *
                                              fees?.bookingPercentValue) /
                                            100
                                        : 0
                                      : 0)
                                : parseInt(item?.stagePrice) +
                                    (fees?.isAddBookingFees
                                      ? fees?.maxBookingFee <
                                        (item?.stagePrice *
                                          fees?.bookingPercentValue) /
                                          100
                                        ? fees?.maxBookingFee
                                        : (item?.stagePrice *
                                            fees?.bookingPercentValue) /
                                          100
                                      : 0)
                            ).toFixed(2)}`}
                          </Text>
                        </span>
                      </div>
                      <div
                        className="generic-common-row-div-space-between FullPriceGroup"
                        style={{
                          marginTop: 16,
                          paddingLeft: 32,
                          width: "50%",
                        }}
                      >
                        <span className="FullPriceLabel">
                          <Text variant="r300" marginBottom={8}>
                            Amount received by organiser
                          </Text>
                        </span>
                        <span className="FullPriceDetails">
                          <Text variant="b300" marginBottom={8}>
                            {`${extractCurrencySymbol(
                              ticketCurrency
                            )}${parseFloat(
                              (!fees?.isAddFeesToTicketPrice
                                ? parseInt(item?.stagePrice) +
                                  fees?.platformFee +
                                  (item?.stagePrice * fees?.platformFeePct) /
                                    100 +
                                  fees?.ticketGuardFee +
                                  (fees?.isAddBookingFees
                                    ? fees?.maxBookingFee <
                                      (item?.stagePrice *
                                        fees?.bookingPercentValue) /
                                        100
                                      ? fees?.maxBookingFee
                                      : (item?.stagePrice *
                                          fees?.bookingPercentValue) /
                                        100
                                    : 0)
                                : parseInt(item?.stagePrice) +
                                  (fees?.isAddBookingFees
                                    ? fees?.maxBookingFee <
                                      (item?.stagePrice *
                                        fees?.bookingPercentValue) /
                                        100
                                      ? fees?.maxBookingFee
                                      : (item?.stagePrice *
                                          fees?.bookingPercentValue) /
                                        100
                                    : 0)) -
                                (fees?.platformFee +
                                  (item?.stagePrice * fees?.platformFeePct) /
                                    100 +
                                  fees?.ticketGuardFee +
                                  (fees?.isAddBookingFees
                                    ? fees?.maxBookingFee <
                                      (item?.stagePrice *
                                        fees?.bookingPercentValue) /
                                        100
                                      ? fees?.maxBookingFee * 0.2
                                      : ((item?.stagePrice *
                                          fees?.bookingPercentValue) /
                                          100) *
                                        0.2
                                    : 0))
                            ).toFixed(2)}`}
                          </Text>
                        </span>
                      </div>
                    </div>
                  </div>
                )
              );
            })}
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      style={{
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 30,
        paddingBottom: 0,
      }}
      className="event-about-div CommonBoxCont"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          paddingLeft: 24,
          paddingRight: 24,
        }}
      >
        <div className="generic-common-row-div">
          <Text variant={"m500"}>
            {ticketName ? ticketName : `Ticket ${index + 1}`}
          </Text>
          <TooltipPopup />
        </div>

        <div
          onClick={() => onDeleteClicked(index)}
          className="generic-common-row-div"
        >
          <Text
            color={"var(--red)"}
            marginRight={8}
            marginTop={3}
            variant="m300"
          >
            Remove
          </Text>
          <BinFilled
            fill={getComputedStyle(document.documentElement).getPropertyValue(
              "--red"
            )}
          />
        </div>
        {/* )} */}
      </div>
      <div style={{ marginTop: 30 }} className="generic-common-divider" />

      <AccordionCustom
        heading={"Ticket details"}
        renderContent={() => renderTicketDetails()}
        isOpen={true}
      />

      <AccordionCustom
        heading={"Age restrictions"}
        renderContent={() => renderAgeRestrictions()}
        isOpen={true}
      />

      <AccordionCustom
        heading={"Ticket release"}
        renderContent={() => renderTicketRelease()}
        isOpen={true}
      />

      {/* <AccordionCustom
        heading={"Staged releases"}
        renderContent={() => renderStagedReleases()}
      /> */}

      {shouldShowSubmitButton && (
        <div
          className="bottom-buttons-container-edit-view"
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 40,
            marginBottom: 40,
            justifyContent: "center",
          }}
        >
          <ButtonPrimary
            isSecondary={true}
            label={"Cancel"}
            onPress={() => onDiscardClicked()}
            width={328}
            style={{ marginRight: 24 }}
          />
          <ButtonPrimary
            label={"Save changes"}
            onPress={() => onSaveClicked()}
            width={328}
          />
        </div>
      )}
    </div>
  );
}
