import React from "react";
import { useNavigate } from "react-router-dom";
import "./ForgotPassword.css";
import HoverBGButton from "../../Component/Common/Buttons/HoverBGButton/HoverBGButton";
import Footer from "../../Component/FooterNew/FooterNew";
import Text from "../../Component/Common/Text";
import ButtonPrimary from "../../Component/Common/Buttons/ButtonPrimary/ButtonPrimary";
import { ReactComponent as SuccessFilled } from "../../assets/svgs/SuccessFilled.svg";

function PasswordUpdated() {
  const navigate = useNavigate();

  return (
    <div className="page-wrapper-fp">
      <div className="login-box-container-fp password-updated-container">
        <div className="login-inner-box-container-fp">
          <HoverBGButton icon={<SuccessFilled />} size={80} />

          <div className="topLogin-text">
            <div className="topLogin-tittle-text">
              <Text variant="m700" marginTop={16} marginBottom={24}>
                Password updated!
              </Text>
            </div>

            <div className="bottomLogin-prg-text">
              <Text variant="r300" marginBottom={32} textAlign={"center"}>
                Your password has been successfully reset. Try signing in to
                your account below.
              </Text>
            </div>
          </div>
        </div>
        <div className="login-sign-in-up-container">
          <div className="bottomLogin-btn">
            <ButtonPrimary
              width={360}
              label={"Sign in"}
              onPress={() => navigate(-4)}
            />
          </div>

          <div className="backTo-signIn">
            <Text cursor={"pointer"}
              onClick={() => navigate(-4)}
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue("--midBlue")}
              variant="m300"
              marginTop={30}
            >
              Back to Home
            </Text>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PasswordUpdated;
