import React, { useEffect, useRef, useState } from "react";
import "./TicketAddOn.css";
import DatePicker from "react-datepicker";
import { FileDrop } from "react-file-drop";
import { useSelector } from "react-redux";
import "../EventDetails/EventDetails.css";
import { ReactComponent as BinFilled } from "../../../assets/svgs/BinFilled.svg";
import { ReactComponent as GalleryAddFilled } from "../../../assets/svgs/GalleryAddFilled.svg";
import HoverBGButton from "../../../Component/Common/Buttons/HoverBGButton/HoverBGButton";
import TooltipPopup from "../../../Component/Common/TooltipPopup/TooltipPopup";
import InputField from "../../Common/InputFields/InputFieldPrimary/InputFieldPrimary";
import ButtonPrimary from "../../Common/Buttons/ButtonPrimary/ButtonPrimary";
import DropdownInput from "../../../Component/Common/DropdownInput/DropdownInput";
import Text from "../../Common/Text";

export default function TicketAddOnNew({
  onNameChange,
  onQuantityChange,
  onDescriptionChange,
  onCurrencyChange,
  onPriceChange,
  onAddedToChange,
  addAnotherAddOn,
  shouldShowSubmitButton,
  toHaveAccessibleErrorMessageTicketAddOn,
  onSaveClicked,
  onDiscardClicked,
  onDeleteClicked,
  addOnName,
  addOnQuantity,
  addOnSoldQuantity = 0,
  addOnDescription,
  addOnImage = "",
  addOnCurrency,
  addOnPrice,
  addOnAddedTo,
  eventStartDate,
  eventStartTime,
  ticketValidDateStart = "DD/MM/YYYY",
  ticketValidTimeStart = '-',
  ticketValidDateEnd= "DD/MM/YYYY",
  ticketValidTimeEnd = '-',
  index,
  currencyList,
  addOnCategoryList,
  showAddAnotherAddOn,
  onAddAnotherAddOnClicked,
  onRemoveAddOnClicked,
  onTicketValidDateChange,
  onTicketValidTimeChange,
  onTicketValidEndDateChange,
  onTicketValidEndTimeChange,
  onAddOnImageChange = () => {},
}) {
  // const currencyList = useSelector((state) => state.currencyData?.currencyData);
  const remainingCharCount = addOnDescription?.length;
  const hiddenFileInput = useRef(null);
  const dragInputRef = useRef(null);

  const [selectedReleaseDateValueTicket, setSelectedReleaseDateValueTicket] =
      useState(ticketValidDateStart);
    const [
      isDatePickerVisibleReleaseDateTicket,
      setIsDatePickerVisibleReleaseDateTicket,
    ] = useState(false);
    const [
      selectedReleaseEndDateValueTicket,
      setSelectedReleaseEndDateValueTicket,
    ] = useState(ticketValidDateEnd);
    const [
      isDatePickerVisibleReleaseEndDateTicket,
      setIsDatePickerVisibleReleaseEndDateTicket,
    ] = useState(false);

  const onDragInputChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      onAddOnImageChange(URL.createObjectURL(event.target.files[0]));
    }
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    convertAndSetLogo(event, fileUploaded);
    if (event.target.files && event.target.files[0]) {
      onAddOnImageChange(URL.createObjectURL(event.target.files[0]));
    }
    event.target.value = null;
  };

  const convertAndSetLogo = (e, file) => {
    e.preventDefault();
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result;
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    console.log("showAddAnotherAddOn::::::", showAddAnotherAddOn);
  }, [showAddAnotherAddOn]);

  const generateTimeArray = () => {
    const timeArray = [];
    for (let hours = 0; hours < 24; hours++) {
      for (let minutes = 0; minutes < 60; minutes += 15) {
        const formattedTime = `${String(hours).padStart(2, "0")}:${String(
          minutes
        ).padStart(2, "0")}`;
        timeArray.push({ name: formattedTime });
      }
    }
    return timeArray;
  };

  const generateCustomTimeArray = (startTime) => {
    console.log("startTime", startTime);
    const [startHour, startMinute] = startTime?.split(":").map(Number);
    const timeArray = [];

    // Generate times from start time to the end of the day
    for (let hours = startHour; hours < 24; hours++) {
      for (
        let minutes = hours === startHour ? startMinute : 0;
        minutes < 60;
        minutes += 15
      ) {
        const formattedTime = `${String(hours).padStart(2, "0")}:${String(
          minutes
        ).padStart(2, "0")}`;
        timeArray.push({ name: formattedTime });
      }
    }

    return timeArray;
  };

  return (
    <div className="event-about-div">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="generic-common-row-div">
          <Text marginRight={13} variant={"m500"}>
            {"Add On " + String(index + 1)}
          </Text>
          <TooltipPopup />
        </div>

        <div
          onClick={() => onDeleteClicked(index)}
          className="generic-common-row-div"
        >
          <Text
            color={"var(--red)"}
            marginRight={8}
            marginTop={3}
            variant="m300"
          >
            Remove
          </Text>
          <BinFilled
            fill={getComputedStyle(document.documentElement).getPropertyValue(
              "--red"
            )}
          />
        </div>
      </div>

      <div
        style={{ marginTop: 40, marginBottom: 40 }}
        className="generic-common-divider"
      />

      <div
        className="CommonFormFieldRow addOn-field-block"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          class="CommonFormGroup"
          style={{
            display: "flex",
            flexDirection: "column",
            //marginTop: 32,
            flex: 1 / 2,
            paddingRight: 32,
          }}
        >
          <Text variant="m300" marginBottom={8}>
            Add On name*
          </Text>

          <InputField
            inputValue={addOnName}
            verifyNotNeed={true}
            placeholder={"Enter Add On name"}
            secureTextEntry={false}
            editable={true}
            type={"eventName"}
            withOutIcon={true}
            isError={false}
            onChangeValue={(text) => onNameChange(text)}
            onBlur={(value) => {}}
            assistive={
              toHaveAccessibleErrorMessageTicketAddOn?.includes("name")
                ? "*Name is required"
                : ""
            }
          />

          <Text variant="m300" marginTop={24} marginBottom={8}>
            {"Add On description*"}
          </Text>
          <div className="ticket-input-container">
            <textarea
              className="ticket-top-aligned-input"
              style={{
                display: "flex",
                flex: 1,
                border: "none",
                borderRadius: 4,
                outline: "none",
                paddingLeft: 16,
                paddingTop: 12,
                verticalAlign: "top",
                color: "var(--midnightBlue)",
              }}
              maxLength={400}
              type={"text"}
              placeholder={"Enter Add On description"}
              aria-multiline="true"
              autoCapitalize={"words"}
              value={addOnDescription}
              onChange={(event) => {const cleanedValue = event.target.value?.replace(/\u2022\s?/g, ""); onDescriptionChange(cleanedValue)}}
              rows={5}
            />
          </div>
          <Text
            marginTop={2}
            variant={"label"}
            alignSelf={"flex-start"}
            color={"var(--red)"}
          >
            {toHaveAccessibleErrorMessageTicketAddOn?.includes("description")
              ? "*Description is required"
              : ""}
          </Text>
          <Text
            color={getComputedStyle(document.documentElement).getPropertyValue(
              "--midBlue"
            )}
            alignSelf={"flex-end"}
            variant={"label"}
          >
            {remainingCharCount}/400 characters
          </Text>
        </div>

        <div
          class="CommonFormGroup"
          style={{
            display: "flex",
            flexDirection: "column",
            //marginTop: 32,
            flex: 1 / 2,
            paddingLeft: 32,
          }}
        >
          <Text variant="m300" marginBottom={8}>
            Image
          </Text>
          <div className="banner-container-img-bs">
            {addOnImage === "" ? (
              <FileDrop
                onFrameDragEnter={(event) =>
                  console.log("onFrameDragEnter", event)
                }
                onFrameDragLeave={(event) =>
                  console.log("onFrameDragLeave", event)
                }
                onFrameDrop={(event) => console.log("onFrameDrop", event)}
                onDragOver={(event) => console.log("onDragOver", event)}
                onDragLeave={(event) => console.log("onDragLeave", event)}
                onDrop={(files, event) => (
                  event.preventDefault(),
                  onAddOnImageChange(URL.createObjectURL(files[0]))
                )}
                onTargetClick={() => console.log("onTargetClick!!!!!!!!!!!!")}
              >
                <input
                  onChange={onDragInputChange}
                  ref={dragInputRef}
                  type="file"
                  className="hidden"
                />
                <div className="banner-container-overview">
                  <HoverBGButton
                    icon={<GalleryAddFilled height={24} width={24} />}
                    size={48}
                  />
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "row",
                      justifyContent: "start",
                      alignItems: "start",
                      paddingLeft: 21,
                      paddingRight: 16,
                      marginTop: 8,
                    }}
                  >
                    <Text cursor={"pointer"}
                      onClick={() => hiddenFileInput.current.click()}
                      textAlign={"center"}
                      width={"100%"}
                      underline={true}
                      variant="m300"
                      color={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--midBlue")}
                    >
                      Click to upload
                    </Text>
                    <Text textAlign={"start"} width={"55%"} variant="m300">
                      or drag
                    </Text>
                  </div>
                  <Text variant="m300">and drop</Text>
                  <Text
                    color={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue("--grey")}
                    variant="r200"
                    marginTop={16}
                  >
                    JPG (max. 600 x 600px)
                  </Text>

                  <input
                    type="file"
                    accept="image/png, image/jpeg, image/jpg, image/webp"
                    onChange={handleChange}
                    ref={hiddenFileInput}
                    style={{ display: "none" }}
                  />
                </div>
              </FileDrop>
            ) : (
              <div className="banner-container-img-bs">
                <img
                  content="center"
                  alt=""
                  style={{
                    height: "100%",
                    width: "100%",
                    borderRadius: 4,
                    alignSelf: "stretch",
                    objectFit: "cover",
                  }}
                  src={addOnImage}
                />
                <div style={{ position: "absolute", right: 14, marginTop: 16 }}>
                  <HoverBGButton
                    icon={
                      <BinFilled
                        fill={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue("--midBlue")}
                        height={16}
                        width={16}
                      />
                    }
                    size={32}
                    backgroundHover={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue("--white")}
                    onPress={() => onAddOnImageChange("")}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div
          // style={{ marginTop: "36px" }}
          className="generic-common-row-div-space-between"
        >
          <div
            className="CommonFormFieldRow"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              // marginTop: 28,
              width: "50%",
              marginRight: "64px",
            }}
          >
            <div
              className="CommonFormGroup"
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                marginBottom: 0,
              }}
            >
              <Text variant="m300" marginBottom={8}>
              Valid From
              </Text>
              <DropdownInput
                resetRequired={true}
                showDropdown={isDatePickerVisibleReleaseDateTicket}
                onToggleDropdown={() =>
                  setIsDatePickerVisibleReleaseDateTicket(
                    !isDatePickerVisibleReleaseDateTicket
                  )
                }
                eventCategory={selectedReleaseDateValueTicket}
                data={[]}
                editable={eventStartDate === "DD/MM/YYYY" ? false : true}
                resetBtnClicked={() => {
                  setSelectedReleaseDateValueTicket("DD/MM/YYYY");
                  onTicketValidDateChange("DD/MM/YYYY");
                }}
                customRender={() => {
                  return (
                    <div style={{ marginBottom: -10 }}>
                      <DatePicker
                        shouldCloseOnSelect={true}
                        onChange={(update) => {
                          setSelectedReleaseDateValueTicket(
                            new Date(update).toLocaleDateString("en-GB")
                          );
                          onTicketValidDateChange(
                            new Date(update).toLocaleDateString("en-GB")
                          );
                          setIsDatePickerVisibleReleaseDateTicket(
                            !isDatePickerVisibleReleaseDateTicket
                          );
                        }}
                        startDate={new Date()}
                        inline
                        minDate={
                          // new Date(
                          //   eventStartDate.split("/")[2],
                          //   eventStartDate.split("/")[1] - 1,
                          //   eventStartDate.split("/")[0]
                          // ) || null
                          null
                        }
                      />
                    </div>
                  );
                }}
              />
            </div>
            <div
              className="CommonFormGroup"
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                marginLeft: 24,
                marginBottom: 0,
              }}
            >
              <Text variant="m300" marginBottom={8}>
                Time
              </Text>
              <DropdownInput
                eventCategory={ticketValidTimeStart}
                data={
                  selectedReleaseDateValueTicket === eventStartDate
                    ? generateCustomTimeArray(eventStartTime)
                    : generateTimeArray()
                }
                onSelectedItem={(value) => {
                  onTicketValidTimeChange(value);
                }}
                editable={
                  selectedReleaseDateValueTicket === "DD/MM/YYYY" ? false : true
                }
              />
              
            </div>
            
          </div>
          
          <div
            className="CommonFormFieldRow"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              // marginTop: 28,
              width: "50%",
            }}
          >
            <div
              className="CommonFormGroup"
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                marginBottom: 0,
              }}
            >
              <Text variant="m300" marginBottom={8}>
              Valid Until
              </Text>
              <DropdownInput
                resetRequired={true}
                showDropdown={isDatePickerVisibleReleaseEndDateTicket}
                onToggleDropdown={() =>
                  setIsDatePickerVisibleReleaseEndDateTicket(
                    !isDatePickerVisibleReleaseEndDateTicket
                  )
                }
                eventCategory={selectedReleaseEndDateValueTicket}
                data={[]}
                editable={
                  selectedReleaseDateValueTicket === "DD/MM/YYYY" ||
                  ticketValidTimeStart === "00:00 "
                    ? false
                    : true
                }
                resetBtnClicked={() => {
                  setSelectedReleaseEndDateValueTicket("DD/MM/YYYY");
                  onTicketValidEndDateChange("DD/MM/YYYY");
                }}
                customRender={() => {
                  return (
                    <div style={{ marginBottom: -10 }}>
                      <DatePicker
                        shouldCloseOnSelect={true}
                        onChange={(update) => {
                          setSelectedReleaseEndDateValueTicket(
                            new Date(update).toLocaleDateString("en-GB")
                          );
                          onTicketValidEndDateChange(
                            new Date(update).toLocaleDateString("en-GB")
                          );
                          setIsDatePickerVisibleReleaseEndDateTicket(
                            !isDatePickerVisibleReleaseEndDateTicket
                          );
                        }}
                        startDate={new Date()}
                        inline
                        minDate={
                          new Date(
                            selectedReleaseDateValueTicket.split("/")[2],
                            selectedReleaseDateValueTicket.split("/")[1] - 1,
                            selectedReleaseDateValueTicket.split("/")[0]
                          ) || null
                        }
                        // minDate={new Date()}
                      />
                    </div>
                  );
                }}
              />
              
            </div>
            <div
              className="CommonFormGroup"
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                marginLeft: 24,
                marginBottom: 0,
              }}
            >
              <Text variant="m300" marginBottom={8}>
                Time
              </Text>
              <DropdownInput
                eventCategory={ticketValidTimeEnd}
                data={
                  selectedReleaseDateValueTicket ===
                  selectedReleaseEndDateValueTicket
                    ? generateCustomTimeArray(ticketValidTimeStart)
                    : generateTimeArray()
                }
                onSelectedItem={(value) => {
                  onTicketValidEndTimeChange(value);
                }}
                editable={
                  selectedReleaseEndDateValueTicket === "DD/MM/YYYY"
                    ? false
                    : true
                }
              />
              
            </div>
          </div>
        </div>
        {/* <Text color={"var(--textDisabled)"} variant={"r300"}> */}
        <Text
                marginTop={8}
                variant={"label"}
                alignSelf={"flex-start"}
                color={"var(--textDisabled)"}
              >
                {
                  "Please ensure you enter the start and end date and time this ticket is valid for.  If you leave this blank then the ticket will be valid for the full duration of the event"
                }
              </Text>

      <div
        style={{ alignItems: "flex-start", marginTop: 16 }}
        className="generic-common-row-div-space-between CommonFormFieldRow"
      >
        <div
          class="CommonFormGroup full-width-col"
          style={{
            display: "flex",
            flexDirection: "column",
            //marginTop: 32,
            flex: 1 / 2,
            paddingRight: 32,
          }}
        >
          <Text variant="m300" marginBottom={8}>
            Quantity*
          </Text>

          <InputField
           minNumberValue={addOnSoldQuantity || false}
            isNumeric={true}
            inputValue={addOnQuantity}
            verifyNotNeed={true}
            placeholder={"Enter Add On quantity"}
            secureTextEntry={false}
            editable={true}
            type={"eventName"}
            withOutIcon={true}
            isError={false}
            onChangeValue={(text) => onQuantityChange(text)}
            onBlur={(value) => {}}
            assistive={
              toHaveAccessibleErrorMessageTicketAddOn?.includes("quantity")
                ? "*Quantity is required"
                : ""
            }
          />
        </div>
        <div
          className="full-width-col"
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: 32,
            flex: 1 / 2,
            paddingLeft: 32,
          }}
        />
      </div>

      <div
        style={{ alignItems: "flex-start" }}
        className="generic-common-row-div-space-between CommonFormFieldRow"
      >
        <div
          class="CommonFormGroup"
          style={{
            display: "flex",
            flexDirection: "column",
            //marginTop: 32,
            flex: 1 / 2,
            paddingRight: 32,
          }}
        >
          {/* <Text variant="m300" marginBottom={8}>
            Currency
          </Text>

          <DropdownInput
            type={"currency"}
            eventCategory={addOnCurrency}
            data={currencyList}
            onSelectedItem={(value) => onCurrencyChange(value)}
            editable={false}
          /> */}

          <Text variant="m300" marginBottom={8}>
            Add On price*
          </Text>

          <InputField
            isNumeric={true}
            inputValue={addOnPrice}
            verifyNotNeed={true}
            placeholder={"Enter Add On price"}
            secureTextEntry={false}
            editable={true}
            type={"eventName"}
            withOutIcon={true}
            isError={false}
            onChangeValue={(text) => onPriceChange(text)}
            onBlur={(value) => {}}
            assistive={
              toHaveAccessibleErrorMessageTicketAddOn?.includes("price")
                ? "*Price is required"
                : ""
            }
          />
        </div>
        <div
          class="CommonFormGroup"
          style={{
            display: "flex",
            flexDirection: "column",
            //marginTop: 32,
            flex: 1 / 2,
            paddingLeft: 32,
          }}
        ></div>
      </div>

      {addOnAddedTo?.length > 0 ? (
        <div>
          {addOnAddedTo.map((item, ind) => {
            return (
              <div
                style={{ width: "100%" }}
                className="generic-common-column-div"
              >
                <div
                  style={{ width: "100%" }}
                  className="generic-common-row-div CommonFormFieldRow"
                >
                  <div
                    class="CommonFormGroup"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      //marginTop: 32,
                      width: "50%",
                      paddingRight: 32,
                    }}
                  >
                    <Text variant="m300" marginBottom={8}>
                      Can be added to*
                    </Text>

                    <DropdownInput
                      isMultiSelect={false}
                      type={"currency1"}
                      eventCategory={item}
                      data={addOnCategoryList}
                      onSelectedItem={(value, index) =>
                        onAddedToChange(value, index, ind)
                      }
                      editable={addOnCategoryList?.length > 0 ? true : false}
                    />

                    <Text
                      marginTop={8}
                      variant={"label"}
                      alignSelf={"flex-start"}
                      color={"var(--red)"}
                    >
                      {toHaveAccessibleErrorMessageTicketAddOn?.includes(
                        "addedTo"
                      )
                        ? "*Can be added to is required"
                        : ""}
                    </Text>
                  </div>

                  {item !== "Select an option" && (
                    <ButtonPrimary
                      type={"error"}
                      isSecondary={true}
                      label={"Remove from ticket"}
                      onPress={() => onRemoveAddOnClicked(ind)}
                      width={328}
                      style={{ marginRight: 24, marginTop: 12 }}
                    />
                  )}
                </div>
                {showAddAnotherAddOn &&
                  ind === addOnAddedTo.length - 1 &&
                  addOnCategoryList.length > 0 && (
                    <ButtonPrimary
                      isSecondary={true}
                      label={"Add to another ticket"}
                      onPress={() => onAddAnotherAddOnClicked()}
                      width={328}
                      style={{ marginRight: 24, marginTop: 12 }}
                    />
                  )}
              </div>
            );
          })}
        </div>
      ) : addOnCategoryList.length > 0 ? (
        <div>
          {" "}
          <ButtonPrimary
            isSecondary={true}
            label={"Add to another ticket"}
            onPress={() => onAddAnotherAddOnClicked()}
            width={328}
            style={{ marginRight: 24, marginTop: 12 }}
          />
          <Text
            marginTop={11}
            variant={"label"}
            alignSelf={"flex-start"}
            color={"var(--red)"}
          >
            {toHaveAccessibleErrorMessageTicketAddOn?.includes("addedTo")
              ? "*Needs to select at least one ticket to add on"
              : ""}
          </Text>
        </div>
      ) : null}

      {shouldShowSubmitButton && (
        <div
          className="bottom-buttons-container-edit-view"
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 40,
            justifyContent: "center",
          }}
        >
          <ButtonPrimary
            isSecondary={true}
            label={"Cancel"}
            onPress={() => onDiscardClicked()}
            width={328}
            style={{ marginRight: 24 }}
          />
          <ButtonPrimary
            label={"Save changes"}
            onPress={() => onSaveClicked()}
            width={328}
          />
        </div>
      )}

      {/* {addAnotherAddOn && (
        <ButtonPrimary
          style={{ marginTop: 40 }}
          isSecondary={true}
          label={"Create another Add On"}
          onPress={() => addAnotherAddOn()}
          width={328}
        />
      )} */}
    </div>
  );
}
