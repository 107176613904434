import React, { useState, useEffect } from "react";
import "./OrderList.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ReactComponent as CheckBoxChecked } from "../../assets/svgs/CheckBoxChecked.svg";
import { ReactComponent as CheckBoxUncheck } from "../../assets/svgs/CheckBoxUncheck.svg";
import Tags from "../../Component/Common/Tags/Tags";
import Text from "../../Component/Common/Text";
import Footer from "../../Component/FooterNew/FooterNew";
import SideBar from "../../Component/SideBar/SideBar";
import MenuLink from "../../Component/Common/MenuLink/MenuLink";
import ButtonPill from "../../Component/Common/Buttons/ButtonPill";
import ButtonPrimary from "../../Component/Common/Buttons/ButtonPrimary/ButtonPrimary";
import CentralModalSuccess from "../../Component/Common/CentralModals/CentralModalSuccess";
import OrderCancelModal from "../../Component/Common/CentralModals/OrderCancelModal";
import {
  orderCancel,
  getOrderToCsv,
  getOrderDetails,
  getUserDetails,
  getStripeAccountStatus,
} from "../../services/api";
import {
  BANNER_CONTENT,
  SELECTED_EVENT_CATEGORY_DATA,
  USER_DATA,
  SHOW_ACTIVITY_INDICATOR,
} from "../../actions";

function OrderDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const userInfo = useSelector((state) => state.userData?.userData);
  const currencyList = useSelector((state) => state.currencyData?.currencyData);
  const { state } = location;
  let orderId = state?.orderId;
  const parts = window.location.pathname.split("/");
  let eventId = parts[parts.indexOf("EventDetails") + 1];
  if (!orderId) {
    const parts = window.location.pathname.split("/");
    orderId = parseInt(parts[parts.length - 1], 10);
  }
  const [selectedEventOptionIndex, setSelectedEventOptionIndex] = useState(0);
  const [isRefundSummaryModalVisible, setIsRefundSummaryModalVisible] =
    useState(false);
  const [item, setItem] = useState([]);
  const [isNeedRefund, setIsNeedRefund] = useState(false);
  const [isStripeDetailsSubmitted, setIsStripeDetailsSubmitted] =
    useState("no");
  const [eventSectionOptions, setEventSectionOptions] = useState([
    { name: "Overview", id: 1 },
    { name: "Event details", id: 2 },
    { name: "Venue", id: 3 },
    { name: "Requirements", id: 4 },
    { name: "Fees", id: 5 },
    { name: "Tickets", id: 6 },
    { name: "Add Ons", id: 7 },
  ]);
  var dateUpdated = item?.dateCreated;
  const [event, setEvent] = useState(state?.event || false);
  const [orderItems, setOrderItems] = useState([]);
  const [selectedOrderItems, setSelectedOrderItems] = useState([]);
  const lineItemTitle = ["EMAIL ADDRESS", "DATE", "TOTAL ITEMS", "STATUS"];
  const [isRefundConfirmed, setIsRefundConfirmed] = useState(false);

  useEffect(() => {
    getOrderDetailsRequest();
  }, []);

  useEffect(() => {
    if (
      isStripeDetailsSubmitted === true &&
      userInfo?.kycStatus === "Verified" &&
      // getTimeDiffStatus(item?.startDate, item?.endDate) !== "Expired" &&
      // item?.isPublished == true &&
      !eventSectionOptions.some((option) => option.name === "Guest list") &&
      !eventSectionOptions.some((option) => option.name === "Marketing") &&
      !eventSectionOptions.some((option) => option.name === "Communications")
    ) {
      const tempEventOptions = [...eventSectionOptions];
      tempEventOptions.push({ name: "Guest list", id: 8 });
      tempEventOptions.push({ name: "Marketing", id: 9 });
      tempEventOptions.push({ name: "Communications", id: 9 });
      setEventSectionOptions(tempEventOptions);
    }
  }, [isStripeDetailsSubmitted, userInfo]);

  const getUserDetailsRequest = async () => {
    const result = await getUserDetails();
    if (result.status) {
      dispatch({ type: USER_DATA, payload: result.response });
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
      result?.response?.stripeAccountId &&
      result?.response?.stripeAccountId !== null
        ? getStripeAccountStatusRequest(result.response.stripeAccountId)
        : setIsStripeDetailsSubmitted("not");
    } else {
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
    }
  };

  const getStripeAccountStatusRequest = async (stripeAccountId) => {
    const result = await getStripeAccountStatus({
      stripeAccountId: stripeAccountId,
    });
    if (result.status) {
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
      setIsStripeDetailsSubmitted(result.response.isDetailsSubmitted);
    } else {
      setIsStripeDetailsSubmitted("not");
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
    }
  };

  const orderCancelRequest = async () => {
    setIsRefundSummaryModalVisible(false);
    const trimmedArray = selectedOrderItems.filter((item, ind) => ind !== 0);
    const transformedArray = trimmedArray.map((ticket) => ({
      ticketTypeName: ticket.eventTicketType.name,
      quantity: ticket.quantity,
    }));
    const params = {
      emailAddress: item?.emailAddress,
      orderId: orderId,
      orderItems: transformedArray,
      StripeRefundReason: "requested_by_customer",
      refund: isNeedRefund ? true : false,
    };
    const result = await orderCancel(params);
    if (result?.status) {
      setSelectedOrderItems([]);
      setIsRefundConfirmed(false);
      // setIsRefundSummaryModalVisible(false);
      getOrderDetailsRequest();
    } else {
      // setIsRefundSummaryModalVisible(false);
      const errorMessage = JSON.stringify(result.response);
      dispatch({
        type: BANNER_CONTENT,
        payload: {
          text: errorMessage,
          headingText: "Error Canceling Order",
          showBanner: true,
          type: "Error",
          withClose: true,
          withIcon: true,
          position: "top",
          width: 391,
        },
      });
    }
  };

  const getOrderToCsvRequest = async () => {
    const params = {
      orderId: orderId,
    };
    const result = await getOrderToCsv(params);
    if (result?.status) {
      const url = window.URL.createObjectURL(new Blob([result.response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Order${orderId}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  };

  const getOrderDetailsRequest = async () => {
    const params = {
      orderId: orderId,
    };
    const result = await getOrderDetails(params);
    if (result?.status) {
      setItem(result.response);
      event === false && setEvent(result.response?.event);
      setOrderItems(result.response?.orderItems || []);
      setOrderItems((prevOrderItems) => {
        return prevOrderItems.map((item) => ({
          ...item,
          isSelected: false,
        }));
      });

      setOrderItems((prevOrderItems) => {
        const newOrderItems = [...prevOrderItems];
        newOrderItems.unshift({});
        return newOrderItems;
      });

      setSelectedOrderItems((prevOrderItems) => {
        const newOrderItems = [...prevOrderItems];
        newOrderItems.push({});
        return newOrderItems;
      });

      getUserDetailsRequest();
    } else {
      getUserDetailsRequest();
    }
  };

  const handleOrderSelection = (index, items) => {
    let tempSelectedOrderItems = [...selectedOrderItems];
    let tempOrderItems = [...orderItems];
    if (tempOrderItems[index].isSelected === false) {
      tempSelectedOrderItems.push(tempOrderItems[index]);
      setSelectedOrderItems(tempSelectedOrderItems);
    } else {
      const updatedSelectedOrderItems = tempSelectedOrderItems.filter(
        (item) => item !== tempOrderItems[index]
      );
      setSelectedOrderItems(updatedSelectedOrderItems);
    }
    tempOrderItems[index].isSelected = !tempOrderItems[index].isSelected;
    setOrderItems(tempOrderItems);
  };

  const handleRefundConfirm = () => {
    setIsRefundConfirmed(!isRefundConfirmed);
  };

  const segmentBtnPressedEvent = (index) => {
    setSelectedEventOptionIndex(index);
    dispatch({
      type: SELECTED_EVENT_CATEGORY_DATA,
      payload: index,
    });
    navigate("/EventDetails/" + eventId);
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 400);
  };

  const renderOrderList = ({ items, index }) => {
    return (
      <div
        className="orderDetails-listTable-item"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div
          style={{ padding: 24, borderBottom: "none" }}
          className="order-list-line-item-container"
          key={index}
        >
          <div
            onClick={() =>
              items.orderPaymentStatus === "Approved" &&
              handleOrderSelection(index, items)
            }
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              cursor:
                items.orderPaymentStatus === "Approved" ? "pointer" : "auto",
            }}
          >
            {index !== 0 &&
              (items.isSelected ? <CheckBoxChecked /> : <CheckBoxUncheck />)}
            <Text
              // textWrap={"wrap"}
              marginLeft={index !== 0 ? 24 : 48}
              color={"var(--midnightBlue)"}
              variant={index !== 0 ? "m300" : "m300"}
            >
              {index !== 0 ? `#${items?.eventTicketType?.name}` : "Ticket"}
            </Text>
          </div>
          <div
            style={{
              width: "100%",
              marginLeft: 24,
            }}
          >
            <Text variant={index !== 0 ? "m400" : "m300"}>
              {index !== 0 ? `${items?.quantity || "-"}` : "Quantity"}
            </Text>
          </div>
          <div
            style={{
              width: "100%",
              marginLeft: 24,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Text
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue("--midnightBlue")}
              variant={index !== 0 ? "m400" : "m300"}
            >
              {index !== 0
                ? items?.price !== undefined && items?.price !== null
                  ? `${
                      currencyList?.find((x) => x.id === item?.currencyId)
                        ?.symbol ||
                      currencyList?.find((x) => x.id === item?.currencyId)?.code
                    }${(items?.price).toFixed(2)}`
                  : "-"
                : "Price"}
            </Text>
            {index !== 0 && item?.discountCode && (
              <ButtonPill
                style={{ width: "auto", marginTop: "6px" }}
                type={"completed"}
                varient={"small"}
                label={
                  //  'DISC008 - $100 off'
                  `${item?.discountCode} - ${
                    currencyList?.find((x) => x.id === item?.currencyId)
                      ?.symbol ||
                    currencyList?.find((x) => x.id === item?.currencyId)?.code
                  }${items?.discountAmount}`
                }
              />
            )}
          </div>

          <div
            className="listingList-btns-block"
            style={{
              width: "100%",
              marginLeft: 24,
              display: "flex",
            }}
          >
            {index !== 0 ? (
              <Text
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue("--midnightBlue")}
              variant={"m300"}
            >
              {item?.mobileNumber ? `Ticket sent to +${item?.mobileNumber?.fullNumber}` : 'Ticket not sent'}
            </Text>
            ) : (
              <Text variant={"m300"}>{"Ticket Status"}</Text>
            )}
          </div>

          <div
            className="listingList-btns-block"
            style={{
              width: "100%",
              marginLeft: 24,
              display: "flex",
            }}
          >
            {index !== 0 ? (
              <ButtonPill
                type={
                  items?.amendments?.length > 0 &&
                  items?.amendments[0]?.isRefund === true
                    ? "expired"
                    : items?.orderPaymentStatus === "TicketsCancelled"
                    ? "expired"
                    : items?.orderPaymentStatus === "Approved"
                    ? "completed"
                    : items?.orderPaymentStatus === "PartialRefund"
                    ? "partiallycompleted"
                    : items?.orderPaymentStatus === "Failed" ||
                      items?.orderPaymentStatus === "PaymentExpired"
                    ? "error"
                    : items?.orderPaymentStatus === "Refunded" ||
                      items?.orderPaymentStatus === "NotFound"
                    ? "expired"
                    : "generic"
                }
                varient={"small"}
                label={
                  items?.amendments?.length > 0 &&
                  items?.amendments[0]?.isRefund === true
                    ? "Refunded"
                    : items?.orderPaymentStatus === "TicketsCancelled"
                    ? "Cancelled"
                    : items?.orderPaymentStatus === "Approved"
                    ? "Completed"
                    : items?.orderPaymentStatus === "Pending"
                    ? "Pending payment"
                    : items?.orderPaymentStatus === "NotFound"
                    ? "Not found"
                    : items?.orderPaymentStatus === "PaymentExpired"
                    ? "Payment expired"
                    : items?.orderPaymentStatus === "PartialRefund"
                    ? "Partial cancellation"
                    : items?.orderPaymentStatus === "Failed"
                    ? "Payment failed"
                    : items?.orderPaymentStatus === "Refunded"
                    ? "Ticket cancelled"
                    : items?.orderPaymentStatus
                }
              />
            ) : (
              <Text variant={"m300"}>{"Status"}</Text>
            )}
          </div>
        </div>
        {index !== orderItems?.length - 1 && (
          <div
            className="listLine"
            style={{
              height: 1,
              marginLeft: 24,
              marginRight: 24,
              backgroundColor: getComputedStyle(
                document.documentElement
              ).getPropertyValue("--lightGrey"),
            }}
          />
        )}
      </div>
    );
  };

  return (
    <div className="page-wrapper-attendee">
      {/* <CentralModalSuccess
        title={"Confirm cancel ticket"}
        description={`Are you sure you want to cancel ${
          selectedOrderItems.length > 2 ? `tickets` : `ticket`
        }?`}
        shouldShowButtons={true}
        button1Title={"No"}
        button2Title={"Yes, cancel"}
        onButton1Clicked={() => {
          setIsRefundSummaryModalVisible(false);
        }}
        onButton2Clicked={() => {
          setIsRefundSummaryModalVisible(false);
          orderCancelRequest();
        }}
        show={isRefundSummaryModalVisible}
        onClose={() => setIsRefundSummaryModalVisible(false)}
      /> */}
      <OrderCancelModal
        isOpen={isRefundSummaryModalVisible}
        onClose={() => setIsRefundSummaryModalVisible(false)}
        orderDetailsItem={item}
        selectedOrderItems={selectedOrderItems}
        orderId={orderId}
        currencySymbol={
          currencyList?.find((x) => x.id === item?.currencyId)?.symbol
        }
        isCancelAndRefund={isNeedRefund}
        onCancelButtonClicked={() => orderCancelRequest()}
      />
      <div className="main-body-content">
        <div id="sidebar" className="home-header-sideNav">
          <SideBar
            onChangeOption={(index) =>
              navigate("/Home", {
                state: { selectedIndex: index },
              })
            }
            createEventClicked={() => navigate("/CreateEvent")}
            onGoToEventClicked={(eventId) =>
              navigate(`/EventDetails/${eventId}`, {
                state: {
                  eventId: eventId,
                },
              })
            }
          />
        </div>

        <div className="rht-body-inner-content">
          <div className="order-content-div listing-top-headerPagination">
            <MenuLink
              // btn2Label={"Cancel event"}
              labelArray={[
                { label: "Home", id: 1 },
                { label: event?.name, id: 2 },
              ]}
              withCog={false}
              onMenuLinkPress={(ind) =>
                ind === 0
                  ? navigate("/Home")
                  : ind === 1
                  ? navigate("/EventDetails/" + eventId)
                  : null
              }
            />
            <div
              style={{ paddingTop: 24 }}
              className="event-details-top-after-menulink-container"
            >
              <Text variant={"m600"}>{event?.name}</Text>

              <div className="event-details-event-scroll-container">
                <div className="event-detailsTop-btns-inner">
                  {eventSectionOptions.map((items, ind) => {
                    return (
                      <div key={ind} style={{ marginRight: 12 }}>
                        <Tags
                          paddingVertical={8}
                          label={items.name}
                          SourceIcon={items.icon}
                          selected={
                            ind == selectedEventOptionIndex ? true : false
                          }
                          onChangeSelection={() => segmentBtnPressedEvent(ind)}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="listingPagination-block">
              <MenuLink
                style={{
                  paddingTop: 16,
                  paddingBottom: 16,
                  borderBottomColor: getComputedStyle(
                    document.documentElement
                  ).getPropertyValue("--lightGrey"),
                  borderBottomWidth: "1px !important",
                  boxShadow: "0.3px 0.3px 0.3px 0.3px rgba(0, 0, 0, 0.05)",
                  marginBottom: 0,
                }}
                labelArray={[
                  { label: "Overview", id: 1 },
                  { label: "Order history", id: 2 },
                  { label: `Order #${orderId}`, id: 2 },
                ]}
                withCog={false}
                isForOrderDetails={true}
                onMenuLinkPress={(ind) =>
                  ind === 0
                    ? navigate("/EventDetails/" + eventId)
                    : ind === 1
                    ? navigate(`/EventDetails/${eventId}/OrderList`, {
                        state: {
                          eventId: eventId,
                          event: event,
                        },
                      })
                    : null
                }
              />
            </div>

            <div
              style={{ marginBottom: 0 }}
              className="order-list-parent-content"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  // flex: 1 / 2,
                }}
              >
                <Text variant={"m600"}>{`Order #${orderId}`}</Text>
              </div>
             
            </div>

            <div
                    className="orderDetails-lineItem-data fullname-order-details-container"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: 40,
                      marginLeft: 48,
                    }}
                  >
                    <Text
                      marginBottom={12}
                      color={"var(--midBlue)"}
                      variant={"m300"}
                    >
                      {"FULL NAME"}
                    </Text>
                    <div style={{ display: "flex" }}>
                      
                        <Text variant={"m400"}>
                        {`${item?.firstName} ${item?.lastName}`}
                        </Text>
                      
                    </div>
                  </div>

            <div
              style={{ marginLeft: 48, marginRight: 48, marginBottom: 48 }}
              className="order-list-line-item-title-container orderDetails-lineItem-tittle-container"
            >
              {lineItemTitle.map((items, ind) => {
                return (
                  <div
                    className="orderDetails-lineItem-data"
                    style={{
                      width: "100%",
                      marginLeft: ind === 0 ? 0 : 24,
                      display: "flex",
                      flexDirection: "column",
                      marginRight: 1,
                    }}
                  >
                    <Text
                      marginBottom={12}
                      color={"var(--midBlue)"}
                      variant={"m300"}
                    >
                      {items}
                    </Text>
                    <div style={{ display: "flex" }}>
                      {ind === 3 ? (
                        <ButtonPill
                          type={
                            item?.paymentStatus === "Approved"
                              ? "completed"
                              : item?.paymentStatus === "PartialRefund"
                              ? "partiallycompleted"
                              : item?.paymentStatus === "Failed" ||
                                item?.paymentStatus === "PaymentExpired"
                              ? "error"
                              : item?.paymentStatus === "Refunded" ||
                                item?.paymentStatus === "NotFound" ||
                                item?.paymentStatus === "TicketsRefunded" ||
                                item?.paymentStatus === "TicketsCancelled"
                              ? "expired"
                              : "generic"
                          }
                          varient={"small"}
                          label={
                            item?.paymentStatus === "Approved"
                              ? "completed"
                              : item?.paymentStatus === "TicketsRefunded"
                              ? "Refunded"
                              : item?.paymentStatus === "TicketsCancelled"
                              ? "Cancelled"
                              : item?.paymentStatus === "Pending"
                              ? "Pending payment"
                              : item?.paymentStatus === "NotFound"
                              ? "Not found"
                              : item?.paymentStatus === "PaymentExpired"
                              ? "Payment expired"
                              : item?.paymentStatus === "PartialRefund"
                              ? "Partial cancellation"
                              : item?.paymentStatus === "Failed"
                              ? "Payment failed"
                              : item?.paymentStatus === "Refunded"
                              ? "Ticket cancelled"
                              : item?.paymentStatus === "PartialCancellation"
                              ? "Partially Cancelled"
                              : item?.paymentStatus
                          }
                        />
                      ) : (
                        <Text variant={ind === 3 ? "r200" : "m400"}>
                          {ind === 0
                            ? item?.emailAddress
                            : ind === 1
                            ? dateUpdated
                              ? `${String(
                                  new Date(dateUpdated).getDate()
                                ).padStart(2, "0")}/${String(
                                  new Date(dateUpdated).getMonth() + 1
                                ).padStart(2, "0")}/${new Date(
                                  dateUpdated
                                ).getFullYear()}`
                              : "-"
                            : ind === 2
                            ? item?.orderItems?.reduce(
                                (accumulator, item) =>
                                  accumulator + item.quantity,
                                0
                              ) || "-"
                            : "Completed"}
                        </Text>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            <div
              style={{
                marginBottom: 40,
                marginLeft: "24px",
                marginRight: "24px",
              }}
              className="order-list-item-parent-content orderDetails-listTable-container"
            >
              {orderItems?.length > 0 ? (
                orderItems.map((items, index) => {
                  return renderOrderList({ items, index });
                })
              ) : (
                <div
                  style={{
                    color: "var(--grey)",
                    textAlign: "center",
                    marginTop: "64px",
                    marginBottom: "64px",
                  }}
                >
                  No attendees have entered this event.
                </div>
              )}
            </div>

            <div
              className="orderDetails-bottomBtn-container"
            >
             
             {(item?.paymentStatus === "Approved" ||
                item?.paymentStatus === "PartialCancellation" || item?.paymentStatus === "PartialRefund") ? <ButtonPrimary
                    disabled={selectedOrderItems.length > 1 ? false : true}
                    label={`${
                      selectedOrderItems.length > 2 ? `Cancel` : `Cancel`
                    }${
                      selectedOrderItems.length > 1
                        ? ` (${selectedOrderItems.length - 1} ${
                            selectedOrderItems.length > 2 ? `items` : `item`
                          })`
                        : ""
                    }`}
                    onPress={() => {
                      setIsRefundSummaryModalVisible(true);
                      setIsNeedRefund(false);
                    }}
                    // width={328}
                    style={{}}
                  /> : null}
                  {(item?.paymentStatus === "Approved" ||
                item?.paymentStatus === "PartialCancellation" || item?.paymentStatus === "PartialRefund")
                //  && (item?.event?.refundsAllowed === true)) 
                 ? 
                 <ButtonPrimary
                    disabled={selectedOrderItems.length > 1 ? false : true}
                    label={`${
                      selectedOrderItems.length > 2
                        ? `Cancel and refund`
                        : `Cancel and refund`
                    }${
                      selectedOrderItems.length > 1
                        ? ` (${selectedOrderItems.length - 1} ${
                            selectedOrderItems.length > 2 ? `items` : `item`
                          })`
                        : ""
                    }`}
                    onPress={() => {
                      setIsRefundSummaryModalVisible(true);
                      setIsNeedRefund(true);
                    }}
                    // width={328}
                    style={{ marginLeft: 24}}
                  /> : null}
               

              <ButtonPrimary
                isClickingEffectEnabled={true}
                isSecondary={true}
                label={"Export details"}
                onPress={() => getOrderToCsvRequest()}
                // width={328}
                style={{ marginLeft: 24}}
              />
            </div>
          </div>
        </div>
      </div>

      <div style={{ marginLeft: "-380" }}>
        <Footer />
      </div>
    </div>
  );
}

export default OrderDetails;
